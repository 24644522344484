$baseFontSize: 16px;

$baseFont: 'Conqueror Sans',
'Helvetica Neue',
'Arial',
-apple-system,
BlinkMacSystemFont,
sans-serif;

$img: '../';

$breakpoints: (xs: 740px,
sm: 992px,
md: 1200px,
lg: 1455px);

$gray-1: #1c1c1c;
$gray-2: #303030;
$gray-3: #3c3c3c;
$gray-4: #474747;
$gray-5: #616161;
$gray-6: #eaeaea;
$blue-1: #005057;
$blue-2: #079fad;
$blue-3: #8edbff;
$white: #fff;
