.section--contacts {
  padding-top: 120px;
  padding-bottom: 33px;
  position: relative;
  z-index: auto;
  align-items: stretch;
  flex-direction: column;
  justify-content: stretch;
  height: auto;
  min-height: 0;

  .section__chzto {
    &:before {
      background: url('../img/chz_white.png') no-repeat 0 0;
      background-size: contain;
    }

    &:after {
      background: url('../img/to_outline.png') no-repeat 0 0;
      background-size: contain;
    }

    &--half {
      &:after {
        display: none;
      }
    }
  }

  @include rb(xs) {
    .section__chzto {
      display: block;
      color: #fff;
    }
  }
}

.contacts-item ul{
  padding: 0;
  margin: 0;
  list-style: none;
  margin-bottom: 20px;

  li {
    margin-bottom: 6px;

    b {
      font-weight: 500;
    }
  }
}

.b-contacts-info {
  display: flex;
  align-items: center;
  padding-bottom: 40px;
  min-height: 737px;

  @include rb(md) {
    min-height: 637px;
  }

  @include rb(md) {
    min-height: 0;
  }

  .container {
    position: relative;
    z-index: 0;
  }
}

.map-container {
  position: absolute;
  width: 50%;
  right: 0;
  top: 0;
  height: 100%;
  overflow: hidden;

  @include rb(xs) {
    position: relative;
    height: 400px;
    width: 100%;
    margin-bottom: 30px;
  }
}

.contacts-bg-image {
  position: absolute;
  width: 50%;
  right: 0;
  top: 0;
  bottom: -150px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @include rb(sm) {
    bottom: 0;
  }

  @include rb(xs) {
    display: none;
  }
}

#map {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

[class*="ymaps-2"][class*="-ground-pane"] {
  filter: url('data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale');
  /* Firefox 3.5+ */
  -webkit-filter: grayscale(100%);
  /* Chrome 19+ & Safari 6+ */
}

.needs-popup {
  position: absolute;
  width: 50%;
  height: 100%;
  right: 0;
  top: 0;
  background: $gray-3;
  z-index: 103;
  display: none;
  padding: 155px 0;
  padding-bottom: 120px;

  @include rb(md) {
    padding: 100px 0;
    padding-bottom: 100px;
  }

  @include rb(xs) {
    width: 100%;
  }

  h3 {
    text-transform: uppercase;
    @include f-echoessans;
    font-size: 63px;
    line-height: 0.873016;
    margin-bottom: 20px;
    font-weight: 500;

    @include rb(md) {
      font-size: 52px;
    }

    @include rb(sm) {
      font-size: 36px;
    }

    @include rb(xs) {
      font-size: 30px;
    }
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      margin-bottom: 20px;
    }
  }
}

.needs-popup__inner {
  padding: 0 170px;
  overflow: auto;
  height: 100%;
  width: 100%;

  @media (max-width: 1600px) {
    padding: 0 100px;
  }

  @include rb(md) {
    padding: 0 30px;
  }
}

.needs-popup__close {
  position: absolute;
  width: 120px;
  height: 120px;
  left: 0;
  top: 0;
  cursor: pointer;
  background: $gray-4;

  &:before {
    content: "";
    position: absolute;
    width: 30px;
    height: 30px;
    background: url('../img/close.png') no-repeat 0 0;
    background-size: contain;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  @include rb(md) {
    width: 80px;
    height: 80px;

    &:before {
      width: 20px;
      height: 20px;
    }
  }
}


.needs-popup__square {
  position: absolute;
  width: 120px;
  height: 120px;
  background: $gray-4;
  z-index: -1;

  &--tr {
    top: 0;
    right: 0;
  }

  &--br {
    bottom: 0;
    right: 0;
  }

  &--bl {
    bottom: 0;
    left: 0;
  }

  @include rb(md) {
    width: 80px;
    height: 80px;
  }
}

.director-photo {
  display: none;
  margin-bottom: 25px;

  @include rb(xs) {
    display: block;
  }
}

.b-team {
  width: 100%;
}

.team-list {
  display: flex;
  flex-wrap: wrap;
  margin-right: -300px;
  margin-bottom: -30px;

  @media (max-width: 1640px) {
    margin-right: 0;
  }

  @media (max-width: 1480px) {
    justify-content: center;
    margin-left: -20px;
    margin-right: -20px;
  }
}

.team-item {
  display: flex;
  margin-bottom: 30px;
  align-items: center;
  margin-right: 60px;

  &:nth-child(6n + 3) {
    margin-left: 320px;

    @media (max-width: 1640px) {
      margin-left: 300px;
    }

    @media (max-width: 1480px) {
      margin-left: 0;
    }
  }

  &:nth-child(6n + 4) {
    margin-left: 80px;

    @media (max-width: 1640px) {
      margin-left: 0;
    }
  }

  &:nth-child(6n + 6) {
    margin-left: 480px;

    @media (max-width: 1640px) {
      margin-left: 300px;
    }

    @media (max-width: 1480px) {
      margin-left: 0;
    }
  }

  .section-content {
    max-width: 280px;
  }

  @media (max-width: 1480px) {
    display: block;
    padding: 0 20px;
    margin-right: 0;
  }
}

.team-item__left {
  max-width: 320px;

  img {
    max-width: 100%;
    height: auto;
  }

  @media (max-width: 1850px) {
    max-width: 300px;
  }

  @media (max-width: 1480px) {
    margin-bottom: 25px;
  }
}

.team-item__right {
  padding-left: 80px;

  @media (max-width: 1850px) {
    padding-left: 40px;
  }

  @media (max-width: 1480px) {
    padding-left: 0;
  }
}

.team-item__photo {
  font-size: 0;
  overflow: hidden;
}
