.section {
  position: relative;
  min-height: 976px;
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 140px 0;
  z-index: 0;

  .section__fix {
    min-height: inherit;
    font-size: 0;
    margin: -140px 0;
  }

  .container {
    height: auto;
  }

  @include rb(lg) {
    padding: 100px 0;
    min-height: 850px;

    .section__fix {
      margin: -100px 0;
    }
  }

  @include rb(md) {
    padding: 80px 0;
    min-height: 810px;

    &:after {
      width: 80px;
      height: 80px;
    }

    .section__fix {
      margin: -80px 0;
    }
  }

  @include rb(sm) {
    max-height: none;
    height: auto;
    min-height: 0;
    padding: 60px 0;

    .section__fix {
      display: none;
    }

    &.section--about {
      padding-top: 100px;
    }
  }

  @include rb(xs) {
    min-height: 0;
    height: auto;
    max-height: none;
    padding: 50px 0;

    .section__fix {
      margin: -50px 0;
    }

    &:after {
      display: none;
    }
  }

  &:first-child {
    @include rb(xs) {
      padding-top: 100px;
    }
  }
  .swiper-container {
    & > .container {
      z-index: 100;
    }
  }
}

.swiper-container {
  .container {
    box-sizing: border-box;
  }
}

.section__bottom {
  height: 1px;
  bottom: 0;
  width: 100%;
  left: 0;
  position: absolute;
}

.section--half-bg {
  position: relative;
  z-index: 0;

  &:before {
    content: '';
    position: absolute;
    width: 50%;
    right: 0;
    top: 0;
    height: 100%;
    background: $gray-6;
    z-index: -1;
  }

  @include rb(xs) {
    background: $gray-6;

    &:before {
      display: none;
    }

    .section-content__text {
      color: $gray-3;
    }
  }
}

.section--white-image {
  position: relative;
  z-index: 0;

  &:before {
    content: '';
    position: absolute;
    width: 50%;
    left: 0;
    top: 0;
    height: 100%;
    background: $gray-6;
    z-index: -1;
  }

  @include rb(xs) {
    background: $gray-6;

    &:before {
      display: none;
    }
  }
}

.section--half-bg-reverse {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 50%;
    left: 0;
    top: 0;
    height: 100%;
    background: $gray-6;
  }

  @include rb(xs) {
    background: $gray-6;

    &:before {
      display: none;
    }

    .section-content__text {
      color: $gray-3;
    }
  }
}

.section--light-bg {
  background: $gray-6;
}

.section-control__wrapper,
.section-square__wrapper {
  position: fixed;
  width: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  z-index: 100;
}

.section-square__wrapper {
  left: auto;
  right: 0;
}

.section-control {
  width: 120px;
  height: 120px;
  background: $gray-4;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 100;
  outline: none;
  border: 0;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: $gray-3;
  }

  &:before {
    content: '';
    position: absolute;
    width: 30px;
    height: 40px;
    left: 50%;
    margin-left: -15px;
    top: 50%;
    margin-top: -17px;
    background: url('../img/arrow_down.png') no-repeat 0 0;
    background-size: contain;
  }

  &.section-control--up {
    &:before {
      background: url('../img/arrow_up.png') no-repeat 0 0;
      background-size: contain;
    }
  }

  @include rb(md) {
    width: 80px;
    height: 80px;

    &:before {
      width: 24px;
      height: 30px;
      margin-left: -12px;
      margin-top: -15px;
    }
  }

  @include rb(xs) {
    display: none;
  }
}

.section-square {
  width: 120px;
  height: 120px;
  background: $gray-4;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 100;
  text-align: center;
  padding: 31px 10px;
  color: $gray-3;
  @include f-echoessans;
  font-weight: 500;
  font-size: 63px;
  cursor: default;
  padding-right: 3px;

  @include rb(md) {
    width: 80px;
    height: 80px;
  }

  @include rb(xs) {
    display: none;
  }
}

.b-about {
  position: relative;
  z-index: 0;
}

.section-content {
  max-width: 550px;

  @include rb(lg) {
    max-width: 520px;
  }

  @include rb(md) {
    max-width: 450px;
  }

  @include rb(xs) {
    max-width: none;
  }
}

.section-content--gray {
  .section-content__text {
    color: $gray-3;
  }
}

.section-content__text {
  h2 {
    @include f-echoessans;
    font-weight: 500;
    font-size: 63px;
    line-height: 0.873016;
    margin: 0;
    margin-bottom: 20px;
    font-variant: small-caps;
    letter-spacing: -0.005em;
    margin-bottom: 29px;
  }

  h3 {
    @include f-echoessans;
    font-weight: 300;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 32px;
    font-size: 20px;
    line-height: 1.1;
    letter-spacing: 0.01em;
    max-width: 235px;
  }

  h4 {
    @include f-echoessans;
    font-weight: 500;
    font-size: 20px;
  }

  h5 {
    @include f-echoessans;
    font-weight: 300;
    font-size: 20px;
    line-height: 1.1;
    letter-spacing: 0.01em;
    margin-bottom: 20px;
  }

  p {
    max-width: 310px;
    line-height: 19px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @include rb(lg) {
    h2 {
      font-size: 59px;
    }
  }

  @include rb(md) {
    h2 {
      font-size: 50px;
    }

    h3 {
      font-size: 18px;
      max-width: 200px;
    }
  }

  @include rb(sm) {
    h2 {
      font-size: 40px;
    }

    h3 {
      font-size: 16px;
    }
  }

  @include rb(xs) {
    h2 {
      font-size: 28px;
    }

    h3 {
      font-size: 15px;
    }

    p {
      max-width: none;
    }
  }
}

.section-content__detail {
  width: 492px;
  height: 564px;
  position: absolute;
  left: 50%;
  margin-left: -244px;
  z-index: -1;
  top: -36px;

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: url('../img/detail1.png') no-repeat 0 0;
    background-size: contain;
  }

  &.section-content__detail--mobile {
    display: none;
  }

  @include rb(lg) {
    width: 462px;
    height: 530px;
    margin-left: -231px;
  }

  @include rb(md) {
    width: 380px;
    height: 435px;
    margin-left: -190px;
  }

  @include rb(sm) {
    width: 310px;
    height: 354px;
    margin-left: -155px;
  }

  @include rb(xs) {
    width: 280px;
    height: 334px;
    left: 0;
    display: none;
    position: relative;
    margin: 0;
    margin-bottom: 30px;
    top: 0;

    &:before {
      background: url('../img/detail1_1.png') no-repeat 0 0;
      background-size: contain;
    }

    &.section-content__detail--mobile {
      display: block;
    }
  }
}

.section__chzto-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 427px;

  @include rb(lg) {
    height: 327px;
  }

  @include rb(md) {
    height: 266px;
  }

  @include rb(sm) {
    height: 0;
  }
}

.section__chzto {
  font-size: 0;
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 160px;
  }

  &:before {
    background: url('../img/chz.png') no-repeat 0 0;
    background-size: contain;
    width: 51.1vw;
    max-width: 982px;
    height: 0;
    padding-bottom: 22.2%;
    right: 50%;
    margin-right: 14px;
  }

  &:after {
    background: url('../img/to.png') no-repeat 0 0;
    background-size: contain;
    width: 34.5vw;
    max-width: 663px;
    height: 0;
    padding-bottom: 22.2%;
    left: 50%;
    margin-left: 16px;
  }

  @include rb(md) {

    &:before,
    &:after {
      top: 100px;
    }
  }

  @include rb(xs) {
    @include f-echoessans;
    letter-spacing: 0.01em;
    line-height: 1;
    color: $gray-6;
    font-weight: 900;
    font-size: 130px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 0;
    top: 60px;

    &:before,
    &:after {
      display: none;
    }
  }
}

.section__chzto--text {
  @include f-echoessans;
  font-size: 510px;
  letter-spacing: 0.01em;
  line-height: 1;
  color: $gray-4;
  position: absolute;
  z-index: -1;
  font-weight: 900;
  text-transform: uppercase;
  left: 50%;
  transform: translateX(-54%);
  top: 100px;

  &:before,
  &:after {
    display: none;
  }

  @media (max-width: 1908px) {
    font-size: 450px;
    transform: translateX(-54%);
  }

  @include rb(lg) {
    font-size: 400px;
  }

  @include rb(md) {
    font-size: 320px;
    top: 80px;
  }

  @include rb(sm) {
    font-size: 267px;
  }

  @include rb(xs) {
    font-size: 100px;
    left: 50%;
    transform: translateX(-50%);
    top: 80px;
  }
}

// products slider section
.b-products {
  .container {
    position: relative;
    z-index: 0;
  }
}

.b-products__letter {
  position: absolute;
  width: 286px;
  height: 307px;
  background: url('../img/p_letter.png') no-repeat 0 0;
  background-size: contain;
  left: 287px;
  top: 0;

  @include rb(lg) {
    left: 100px;
  }

  @include rb(md) {
    left: 20px;
    width: 286px;
    height: 243px;
  }

  @include rb(sm) {
    left: -100px;
    width: 187px;
    height: 201px;
  }

  @include rb(xs) {
    left: auto;
    right: -30px;
    width: 150px;
    height: 161px;
  }
}

.b-products__dots {
  position: absolute;
  width: 573px;
  height: 613px;
  background: url('../img/blue_dots.png') no-repeat 0 0;
  background-size: contain;
  left: -398px;
  z-index: -1;
  top: 0;

  @include rb(lg) {
    left: -558px;
  }

  @include rb(md) {
    left: -590px;
  }

  @include rb(sm) {
    left: -690px;
  }

  @include rb(xs) {
    left: -690px;
  }
}


.b-products-slider {
  position: relative;
  overflow: visible;
  max-width: 100vw;
  padding-top: 87px;
  padding-bottom: 87px;

  .swiper-controls {
    left: 287px;
    bottom: -9px;
  }

  @include rb(lg) {
    .swiper-controls {
      left: 100px;
    }
  }

  @include rb(md) {
    .swiper-controls {
      left: 15px;
    }
  }

  @include rb(sm) {
    padding-top: 0;
    padding-bottom: 0;

    .swiper-controls {
      bottom: -40px;
    }
  }

  @include rb(xs) {
    padding-bottom: 0;

    .swiper-controls {
      bottom: 0px;
    }
  }
}

.b-products-slide {
  position: relative;
  padding: 40px 0;
  display: flex;

  .container {
    position: relative;
    z-index: 0;
  }

  @include rb(xs) {
    padding-top: 0;
    padding-bottom: 80px;

    .container {
      position: relative;
    }
  }
}

.b-products-slide__img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 492px;
  height: 562px;
  margin-top: 40px;

  img {
    max-width: 100%;
    max-height: 562px;
    height: auto;
    width: auto;
  }

  &.b-products-slide__img--mobile {
    display: none;
  }

  @include rb(lg) {
    height: 450px;
    width: 394px;

    img {
      max-height: 450px;
    }
  }

  @include rb(md) {
    height: 350px;
    width: 306px;

    img {
      max-height: 350px;
    }
  }

  @include rb(sm) {
    left: 40px;
    margin-top: 0;
    transform: translate(0, -50%);

    height: 315px;
    width: 275px;

    img {
      max-height: 315px;
    }
  }

  @include rb(xs) {
    position: relative;
    margin-bottom: 30px;
    left: 0;
    transform: translate(0);
    top: 0;
    display: none;

    &.b-products-slide__img--mobile {
      display: block;
    }
  }
}

.b-products-slide__right {
  width: 50%;
  padding-left: 327px;
  box-sizing: border-box;
  float: right;

  @include rb(lg) {
    padding-left: 220px;
  }

  @include rb(md) {
    padding-left: 190px;
  }

  @include rb(sm) {
    padding-left: 40px;
  }

  @include rb(xs) {
    padding-left: 0;
    width: 100%;
    float: none;
  }
}

// advantages slider section
.b-advantages {
  max-width: 100vw;
  width: 100%;

  .container {
    position: relative;
  }

  >.container {
    z-index: 0;
  }
}

.b-advantages__letter {
  position: absolute;
  width: 365px;
  height: 307px;
  background: url('../img/a_letter.png') no-repeat 0 0;
  background-size: contain;
  z-index: -1;
  left: -102px;
  top: -126px;

  @include rb(sm) {
    width: 265px;
    height: 223px;
    left: -124px;
    top: -50px;
  }

  @include rb(xs) {
    width: 150px;
    height: 124px;
    left: auto;
    top: -20px;
    right: -20px;
  }
}

.b-advantages__dots {

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 313px;
    height: 303px;
    background: url('../img/blue_dots2.png') no-repeat 0 0;
    background-size: contain;
    z-index: -1;
  }

  &:before {
    left: -427px;
    top: -125px;
  }

  &:after {
    right: -318px;
    top: 105px;
  }

  @include rb(sm) {
    &:before {
      left: -450px;
    }
  }
}

.b-advantages-slider {
  overflow: visible;

  .container {
    box-sizing: border-box;
  }

  .swiper-controls {
    right: 141px;
    bottom: -31px;
  }

  @include rb(lg) {
    .swiper-controls {
      right: 50px;
      bottom: 0;
    }
  }

  @include rb(xs) {
    .swiper-controls {
      right: auto;
      left: 15px;
    }
  }
}

.b-advantages-slide {
  position: relative;
  padding-bottom: 18px;

  @include rb(xs) {
    padding-bottom: 80px;
  }
}

.b-advantages-slide__content {
  padding-left: 176px;

  @include rb(lg) {
    padding-left: 0;
  }
}

.b-advantages-slide__img {
  position: absolute;
  width: 686px;
  height: 493px;
  right: 84px;
  margin-top: -14px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &.b-advantages-slide__img--mobile {
    display: none;
  }

  img {
    max-width: 686px;
    max-height: 493px;
    height: auto;
    width: auto;
  }

  @include rb(lg) {
    width: 622px;
    height: 445px;

    img {
      max-width: 622px;
      max-height: 445px;
    }
  }

  @include rb(md) {
    width: 503px;
    height: 361px;

    img {
      max-width: 503px;
      max-height: 361px;
    }
  }

  @include rb(sm) {
    max-width: 388px;
    max-height: 278px;
    right: -20px;

    img {
      max-width: 388px;
      max-height: 278px;
    }
  }

  @include rb(xs) {
    display: none;
    position: relative;
    transform: translate(0);
    margin: 0;
    right: 0;
    top: 0;
    margin-bottom: 30px;
    max-width: 290px;
    max-height: 208px;

    img {
      max-width: 290px;
      max-height: 208px;
    }

    &.b-advantages-slide__img--mobile {
      display: block;
    }
  }
}

// our client section
.b-clients {
  width: 100%;

  .container {
    display: flex;
    position: relative;
    z-index: 0;
  }

  .section-content__text {
    h2 {
      margin-bottom: 38px;
    }
  }
}

.b-clients__letter {
  position: absolute;
  width: 300px;
  height: 317px;
  background: url('../img/c_letter.png') no-repeat 0 0;
  background-size: contain;
  left: -65px;
  top: -60px;

  @include rb(md) {
    width: 250px;
    height: 264px;
  }

  @include rb(sm) {
    width: 210px;
    height: 222px;
  }

  @include rb(xs) {
    right: 10px;
    left: auto;
    top: 0;
    width: 120px;
    height: 138px;
  }
}

.b-clients__dots {
  position: absolute;
  width: 313px;
  height: 303px;
  background: url('../img/blue_dots2.png') no-repeat 0 0;
  background-size: contain;
  z-index: -1;
  top: -54px;
  left: -425px;
}

.b-clients__map {
  position: absolute;
  width: 953px;
  height: 573px;
  background: url('../img/clients_map.png') no-repeat 0 0;
  background-size: contain;
  z-index: -1;
  top: 10px;
  left: -65px;

  &.b-clients__map--mobile {
    display: none;
  }

  @include rb(lg) {
    width: 799px;
    height: 482px;
  }

  @include rb(md) {
    width: 674px;
    height: 406px;
  }

  @include rb(sm) {
    width: 522px;
    height: 315px;
  }

  @include rb(xs) {
    background: url('../img/clients_map_white.png') no-repeat 0 0;
    background-size: contain;
    margin: 0;
    margin-bottom: 30px;
    margin-top: -30px;
    position: relative;
    width: 290px;
    height: 175px;
    top: 0;
    left: 0;

    &.b-clients__map--mobile {
      display: block;
    }
  }
}

.b-clients__left {
  width: 50%;

  @include rb(xs) {
    display: none;
  }
}

.b-clients__right {
  width: 50%;
  padding-left: 248px;

  @media (max-width: 1630px) {
    padding-left: 100px;
  }

  @include rb(lg) {
    padding-left: 150px;
  }

  @include rb(md) {
    padding-left: 50px;
  }

  @include rb(xs) {
    padding-left: 0;
    width: 100%;
    float: none;
  }
}

.clients-slider {
  position: relative;
  margin-bottom: 37px;
  padding: 0 87px;
  margin-left: -87px;
  margin-right: -87px;

  @media (max-width: 1630px) {
    margin-right: 0;
    padding: 0 82px;
    margin-left: -82px;
  }

  @include rb(lg) {
    padding: 0 60px;
    margin-left: -60px;
  }

  @include rb(md) {
    padding: 0 50px;
    margin-left: -50px;
  }

  @include rb(xs) {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.clients-slide {
  width: 100%;
  height: 144px;
  text-align: center;
  line-height: 144px;
  background: #fff;

  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }

  @media (max-width: 1630px) {
    line-height: 144px;
    height: 144px;
  }

  @include rb(lg) {
    line-height: 114px;
    height: 114px;
  }
}

// b contacts section
.b-contacts {
  width: 100%;

  .container {
    position: relative;
    z-index: 0;

    &:after,
    &:before {
      content: '';
      position: absolute;
      width: 313px;
      height: 303px;
      background: url('../img/blue_dots2.png') no-repeat 0 0;
      background-size: contain;
      z-index: 0;
    }

    &:before {
      left: -426px;
      top: 291px;
    }

    &:after {
      right: -313px;
      top: -30px;
    }
  }

  @include rb(xs) {
    padding: 20px 0;
  }

  .rellax {
    z-index: -2;
    position: absolute;
    left: 0;
    width: 100%;
  }
}

.b-contacts__chzto {
  @include f-echoessans;
  font-size: 510px;
  letter-spacing: 0.01em;
  line-height: 1;
  color: $gray-4;
  position: absolute;
  z-index: -1;
  font-weight: 900;
  text-transform: uppercase;
  left: -284px;
  top: -105px;
  user-select:none;

  @media (max-width: 1908px) {
    font-size: 450px;
    left: -100px;
  }

  @include rb(lg) {
    font-size: 400px;
    left: -80px;
  }

  @include rb(md) {
    font-size: 320px;
    left: -90px;
  }

  @include rb(sm) {
    font-size: 267px;
    left: -70px;
  }

  @include rb(xs) {
    font-size: 100px;
    left: 50%;
    transform: translateX(-50%);
    top: -40px;
  }
}

.b-contacts__inner {
  max-width: 942px;
  margin: 0 auto;
  padding-top: 90px;

  .section-content__text {
    h2 {
      margin-bottom: 7px;
    }
  }

  @include rb(lg) {
    padding-top: 75px;
  }

  @include rb(md) {
    padding-top: 60px;
  }

  @include rb(sm) {
    padding-top: 40px;
  }

  @include rb(xs) {
    padding-top: 0;
  }
}

.bottom-links {
  display: flex;

  @include rb(xs) {
    display: block;
  }
}

.bottom-link__image {
  position: absolute;
  z-index: -1;
  width: 100%;
  left: 0;
  top: 0;
  bottom: -100px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.7;

  @include rb(sm) {
    bottom: 0;
  }
}

.bottom-link {
  padding-top: 69px;
  padding-bottom: 140px;
  width: 50%;
  position: relative;
  z-index: 0;
  min-height: 488px;
  display: flex;
  align-items: center;
  background: #000;
  overflow: hidden;

  h3 {
    @include f-echoessans;
    font-size: 63px;
    line-height: 0.849524;
    font-weight: 500;
    font-variant: small-caps;
    margin: 0;
    margin-bottom: 14px;
  }

  &:first-child {
    justify-content: flex-end;
    text-align: right;
    padding-right: 330px;

    @include rb(lg) {
      padding-right: 150px;
    }

    @include rb(md) {
      padding-right: 120px;
    }

    @include rb(sm) {
      padding-right: 60px;
    }
  }

  &:last-child {
    padding-left: 330px;

    @include rb(lg) {
      padding-left: 150px;
    }

    @include rb(md) {
      padding-left: 120px;
    }

    @include rb(sm) {
      padding-left: 60px;
    }
  }

  @include rb(md) {
    padding-top: 49px;
    padding-bottom: 140px;
    min-height: 400px;

    h3 {
      font-size: 48px;
    }
  }

  @media (max-width: 1100px) {
    padding-top: 39px;
    padding-bottom: 29px;
    min-height: 250px;

    h3 {
      font-size: 40px;
    }
  }

  @include rb(xs) {
    width: 100%;

    h3 {
      font-size: 30px;
    }

    &:first-child {
      justify-content: center;
      text-align: center;
      padding-right: 15px;
    }

    &:last-child {
      justify-content: center;
      text-align: center;
      padding-left: 15px;
    }
  }
}

.contacts-form {
  @include rb(xs) {
    .form-group {
      max-width: none;
    }
  }
}
