.section--docs {
  padding-top: 120px;
  padding-bottom: 33px;
  position: relative;
  z-index: 0;
  height: auto;
  min-height: 0;
  align-items: stretch;
  flex-direction: column;
  justify-content: stretch;

  @include rb(md) {
    padding-top: 80px;
  }

  @include rb(sm) {
    padding-bottom: 20px;
  }


  .section-content--mobile {
    padding: 0 15px;
  }

  .rellax {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
  }
}

.section-tabs-container {
  position: relative;
  background: $gray-2;
  left: 150px;
  width: calc(100% - 300px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 40px;
  padding-top: 25px;
  min-height: 87px;

  @include rb(md) {
    left: 90px;
    width: calc(100% - 180px);
  }

  @include rb(xs) {
    width: calc(100% - 30px);
    left: 15px;
  }
}

.section-tabs {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  align-items: top;
  flex-wrap: wrap;

  li {
    width: 220px;
    margin: 0;
    margin-right: 20px;
    margin-bottom: 20px;

    @include rb(lg) {
      width: 150px;
    }

    &:last-child {
      margin-right: 20px;
      margin-bottom: 20px;
    }
  }

  span {
    max-width: 140px;
    display: block;
  }
}

.section-tabs__link {
  font-size: 15px;
  line-height: 15px;
  text-decoration: none;
  color: $gray-5;
  @include f-echoessans;
  font-weight: 400;

  &.is-active,
  &:hover {
    color: #fff;
  }
}

.section-content--mobile {
  display: none;

  @include rb(xs) {
    display: block;
  }
}

.docs-sliders {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  @include rb(xs) {
    position: relative;
    height: auto;
    padding: 0 15px;
  }
}

.docs-slider {
  width: 100%;
  height: 100%;

  .swiper-slide {
    width: 100%;
  }

  .swiper-container {
    overflow: visible;
    height: 100%;
  }

  .swiper-wrapper {
    height: 100%;
  }

  .swiper-slide {
    height: 100%;
  }

  .swiper-slide-active {
    .doc-image__link {
      transform: translateX(-158px);

      @include rb(lg) {
        transform: translateX(-200px);
      }

      @include rb(sm) {
        transform: translateX(-130px);
      }

      @include rb(xs) {
        transform: translateX(0);
      }
    }
  }
}

.docs-container {
  .container {
    @include clearfix;
    position: relative;
  }
}

.docs-content {
  float: right;
  width: 45%;
  box-sizing: border-box;
  padding-left: 95px;
  padding-top: 146px;
  position: relative;
  z-index: 10;

  .section-content__text {
    padding-top: 0;

    h3 {
      margin-bottom: 22px;
    }

    h2 {
      margin-bottom: 9px;
    }

    p {
      margin-bottom: 18px;
      max-width: 200px;
    }
  }

  @include rb(lg) {
    width: 55%;
    padding-top: 100px;
  }

  @include rb(sm) {
    padding-top: 50px;
    width: 60%;
  }

  @include rb(xs) {
    padding-top: 30px;
    padding-left: 0;
    width: 100%;
    float: none;

    .section-content:not(.section-content--mobile) {
      .section-content__text {

        h2,
        h3 {
          display: none;
        }
      }
    }
  }
}

.doc-image__link {
  width: 464px;
  height: 100%;
  max-height: 660px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;

  img {
    max-width: 464px;
    max-height: 660px;
    width: auto;
    height: auto;
    box-shadow: 0 2px 10px rgba(#000, 0.3);
    border-radius: 3px;
  }

  @include rb(lg) {
    width: 400px;
    height: 100%;
    max-height: 540px;

    img {
      max-width: 400px;
      max-height: 540px;
    }
  }

  @include rb(sm) {
    width: 234px;
    height: 100%;
    max-height: 330px;

    img {
      max-width: 234px;
      max-height: 330px;
    }
  }
}

.docs-content__download {
  margin-bottom: 81px;

  @include rb(md) {
    margin-bottom: 50px;
  }

  @include rb(sm) {
    margin-bottom: 40px;
  }
}

.doc-image {
  text-align: center;
  width: 100%;
  height: 100%;

  @include rb(xs) {
    text-align: left;
  }
}

.docs-container__chzto {
  @include f-echoessans;
  font-size: 510px;
  letter-spacing: 0.01em;
  line-height: 1;
  color: #fff;
  position: absolute;
  z-index: -1;
  font-weight: 900;
  text-transform: uppercase;
  left: 50%;
  transform: translateX(-59%);
  top: 94px;

  @media (max-width: 1908px) {
    font-size: 450px;
    transform: translateX(-57%);
  }

  @include rb(lg) {
    font-size: 400px;
    transform: translateX(-55%);
  }

  @include rb(md) {
    font-size: 320px;
    transform: translateX(-54%);
    top: 40px;
  }

  @include rb(sm) {
    font-size: 267px;
    transform: translateX(-53%);
  }

  @include rb(xs) {
    font-size: 126px;
    transform: translateX(-50%);
    top: 60px;
  }
}

.section-tabs-slider {
  width: 100%;
  overflow: visible;
  flex: 1 1 auto;
  display: flex;
  margin-bottom: 85px;
  min-height: 655px;

  .swiper-wrapper {
    height: auto;
  }

  .swiper-slide {
    height: 100%;
  }

  @include rb(lg) {
    min-height: 540px;
  }

  @include rb(md) {
    min-height: 330px;
  }

  @include rb(sm) {
    min-height: 0;
    margin-bottom: 50px;
  }

  @include rb(xs) {
    padding-bottom: 20px;
    margin-bottom: 0px;
  }
}
