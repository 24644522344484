.header-content {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  padding: 51px 130px;
  overflow: hidden;
  z-index: 102;

  @include rb(md) {
    padding: 32px 90px;
    height: 80px;
    overflow: visible;
  }
}

.midnightHeader {
  height: 120px;
  left: 0;
  width: 100%;
  top: 0;

  &:before,
  &:after {
    content: '';
    position: absolute;
    height: 100%;
    top: 0;
    width: 50%;
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
  }

  &.gray-white {
    &:before {
      background: $gray-3;
    }

    &:after {
      background: $gray-6;
    }

    @include rb(xs) {
      &:after {
        background: $gray-3;
      }
    }
  }

  &.white-gray {
    &:after {
      background: $gray-3;
    }

    &:before {
      background: $gray-6;
    }
  }

  &.white-image {
    // &:after {
    //   background: $gray-3;
    // }

    &:before {
      background: $gray-6;
    }
  }

  &.gray {
    &:before {
      background: $gray-3;
    }

    &:after {
      background: $gray-3;
    }
  }

  &.white {
    &:before {
      background: $gray-6;
    }

    &:after {
      background: $gray-6;
    }
  }
}

.header-bg {
  position: fixed;
  z-index: 100;
  width: 100%;
  left: 0;
  top: 0;
  height: 0;

  span {
    height: 120px;
    display: block;
  }

  @include rb(md) {
    display: none;

    span {
      height: 80px;
    }
  }
}

.logo {
  display: block;
  border: 0;
  outline: none;
  width: 120px;
  height: 120px;
  text-align: center;
  line-height: 120px;
  font-size: 0;
  padding: 22px;
  font-size: 0;
  background: $gray-4;
  transition: background-color 0.3s;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 102;

  &:hover {
    background-color: $gray-3;
  }

  img {
    max-width: 75px;
    max-height: 74px;
    width: auto;
    height: auto;
  }

  @include rb(md) {
    width: 80px;
    height: 80px;
    padding: 6px;

    img {
      max-width: 50px;
      max-height: 50px;
    }
  }
}

.header-phone {
  position: absolute;
  right: 0;
  top: 0;
  height: 120px;
  padding: 53px 0;
  padding-right: 167px;

  @include rb(md) {
    height: auto;
    padding: 0;
    position: relative;
    display: inline-block;
    padding-left: 35px;
  }
}

.header-phone__link {
  color: $gray-5;
  font-size: 17px;
  text-decoration: none;
  @include f-echoessans;
  padding: 3px 8px;
  font-weight: 900;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    height: 2px;
    width: 0;
    bottom: -3px;
    left: 0;
    background: $gray-5;
    transition: all 0.3s;
  }

  &:hover {
    color: $gray-5;

    &:before {
      width: 100%;
    }
  }

  @include rb(md) {
    font-size: 30px;
  }

  @include rb(xs) {
    font-size: 21px;
  }
}

.header-phone__square {
  width: 120px;
  height: 120px;
  background: $gray-4;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
  outline: none;
  border: 0;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: $gray-3;
  }

  &:before {
    content: '';
    position: absolute;
    width: 25px;
    height: 36px;
    background: url('../img/tel.png') no-repeat 0 0;
    background-size: contain;
    left: 50%;
    margin-left: -12px;
    top: 50%;
    margin-top: -18px;
  }

  @include rb(md) {
    width: 20px;
    height: 29px;
    right: auto;
    left: 0;
    opacity: 0.3;

    &:before {
      width: 20px;
      height: 29px;
      margin-left: -10px;
      margin-top: -15px;
    }
  }

  @include rb(xs) {
    width: 16px;
    height: 22px;
  }
}

.nav-list {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 0;

  li {
    display: inline-block;
    vertical-align: top;
    margin: 0;
    margin-right: 32px;

    &:last-child {
      margin-right: 0;
    }
  }

  a {
    color: $gray-5;
    text-transform: uppercase;
    @include f-echoessans;
    font-weight: 900;
    padding: 3px 8px;
    font-size: 17px;
    text-decoration: none;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      height: 2px;
      width: 0;
      bottom: -3px;
      left: 0;
      background: $gray-5;
      transition: all 0.3s;
    }

    &:hover,
    &.is-active {
      &:before {
        width: 100%;
      }
    }
  }

  @include rb(md) {
    text-align: center;
    margin-bottom: 40px;

    li {
      display: block;
      margin: 0;
      margin-bottom: 30px;
    }

    a {
      font-size: 30px;
    }
  }

  @include rb(sm) {
    margin-bottom: 30px;

    li {
      margin-bottom: 25px;
    }

    a {
      font-size: 26px;
    }
  }

  @include rb(xs) {
    margin-bottom: 25px;

    li {
      margin-bottom: 20px;
    }

    a {
      font-size: 21px;
    }
  }
}

.header__mobile {
  @include ra(md) {
    display: block !important;
  }

  @include rb(md) {
    text-align: center;
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    right: 0;
    padding: 20px;
    background: $gray-3;
    z-index: 101;
    display: none;

    >div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 100%;
    }
  }
}

.header-mobile-toggle {
  width: 80px;
  height: 80px;
  background: $gray-3;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 101;
  outline: none;
  padding: 0;
  border: 0;
  cursor: pointer;
  transition: background-color 0.3s;
  display: none;

  span {
    width: 41px;
    height: 4px;
    border-radius: 3px;
    background: #fff;
    margin: 0 auto;
    margin-bottom: 10px;
    display: block;
    transition: all 0.3s;
    transform-origin: 0 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &.is-active {
    span {
      &:nth-child(1) {
        transform: rotate(45deg) translateX(7px) translateY(-6px);
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:nth-child(3) {
        transform: rotate(-45deg) translateX(4px) translateY(5px);
      }
    }
  }

  @include rb(md) {
    display: block;
  }
}
