* {
  box-sizing: inherit;

  &:before,
  &:after {
    box-sizing: inherit;
  }
}

iframe {
  border: none !important;
}

img,
video,
audio {
  max-width: 100%;
  height: auto;
}

button,
input,
select,
textarea {
  appearance: none;
  border-radius: 0;
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

select::-ms-expand {
  display: none;
}

label,
button,
select {
  cursor: pointer;
}

html {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  background-color: #fff;
  color: #000;
  background: $gray-3;
  color: #fff;
  @include f-conquerorsans;
  font-size: 17px;
  line-height: 1.117647;
  font-weight: 300;
  min-width: 320px;
}

.page__layout {
  position: relative;
}

main.main {
  position: relative;
}

.container {
  margin: 0 auto;
  padding: 0 15px;

  @include rb(xs) {
    width: 100%;
  }

  @include ra(xs) {
    width: map-get($breakpoints, xs);
  }

  @include ra(sm) {
    width: map-get($breakpoints, sm);
  }

  @include ra(md) {
    width: map-get($breakpoints, md);
  }

  @include ra(lg) {
    width: map-get($breakpoints, lg);
  }
}

p,
ul,
ol,
address,
figure,
blockquote,
table {
  margin: 0;
  margin-bottom: 20px;
}

blockquote {
  display: block;
  padding-left: 15px;
}

a {
  transition: color 0.3s;
  text-decoration: none;
  color: $blue-2;

  &:hover {
    text-decoration: none;
    color: $blue-3;
  }
}

ul,
ol {
  padding: 0;
  list-style-position: inside;

  li {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  margin-bottom: 1em;
}

::selection {
  background-color: lighten(rgba(#fff, 0.1), 10) !important;
}


@include rb(xs) {
  .rellax {
    transform: translate3d(0, 0, 0) !important;
  }
}
