.section--catalog-slider {
  padding-bottom: 33px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: auto;
  min-height: 0;

  @include rb(sm) {
    min-height: 500px;
  }
}

.catalog-image-slider {
  left: 0;
  right: 0;
  top: 120px;
  bottom: 120px;
  position: absolute;
  text-align: center;
  overflow: visible;

  @include rb(sm) {
    top: 80px;
  }

  @include rb(xs) {
    position: relative;
    top: 0;
    bottom: 0;
    height: 280px;
    text-align: left;
    margin-bottom: 30px;
    overflow: visible;
  }

  .swiper-slide {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .swiper-container {
    overflow: visible;
    height: 100%;
  }

  .swiper-wrapper {
    height: 100%;
  }

  .swiper-slide {
    height: 100%;
  }

  .swiper-slide-active {
    .catalog-slider-img {
      transform: translateX(-150px);

      @include rb(lg) {
        transform: translateX(-200px);
      }

      @include rb(sm) {
        transform: translateX(-130px);
      }

      @include rb(xs) {
        transform: translateX(0);
      }
    }
  }
}

.catalog-slider-slide {
  width: 100%;
}

.catalog-slider-img {
  width: 500px;
  height: 100%;
  max-height: 562px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;

  img {
    max-width: 500px;
    max-height: 562px;
    width: auto;
    height: auto;
  }

  @include rb(lg) {
    width: 350px;
    height: 100%;
    max-height: 450px;

    img {
      max-width: 350px;
      max-height: 450px;
    }
  }

  @include rb(sm) {
    width: 220px;
    height: 100%;
    max-height: 280px;

    img {
      max-width: 220px;
      max-height: 280px;
    }
  }
}

.catalog-texts-container {
  width: 100%;
  min-height: 1px;
  position: relative;
  z-index: 1;
  margin-bottom: 50px;
  min-height: 668px;

  @include rb(lg) {
    min-height: 568px;
  }

  @include rb(sm) {
    min-height: 0;
  }

  .container {
    position: static;
  }
}

.catalog-texts {
  float: right;
  width: 45%;
  box-sizing: border-box;
  padding-left: 95px;
  padding-top: 146px;
  padding-bottom: 40px;

  .section-content__text {
    h3 {
      margin-bottom: 15px;
    }

    p {
      max-width: 280px;
    }
  }

  @include rb(lg) {
    width: 55%;
    padding-top: 140px;
  }

  @include rb(sm) {
    padding-top: 30px;
    width: 60%;
  }

  @include rb(xs) {
    padding-top: 30px;
    padding-left: 0;
    width: 100%;
    float: none;
  }
}

.section-tabs-container--slider {
  padding: 0;

  @include rb(xs) {
    margin-left: -15px;
    width: 100%;
  }
}

.swiper-button-next.swiper-button-lock~.swiper-container .swiper-wrapper {
  justify-content: center;
}

.catalog-links-slider {
  width: 100%;
  padding: 27px 96px;
  text-align: center;

  @include rb(lg) {
    padding-left: 60px;
    padding-right: 60px;
  }

  .swiper-slide {
    text-align: left;
  }

  // .swiper-wrapper {
  //   justify-content: center;
  // }

  .swiper-button-prev {
    left: 17px;
  }

  .swiper-button-next {
    right: 17px;
  }

  .swiper-slide-thumb-active {
    .catalog-menu-link {
      color: #fff;
    }
  }
}

.catalog-menu-link {
  font-size: 15px;
  line-height: 16px;
  color: $gray-5;
  display: inline-block;
  @include f-echoessans;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.3s;
  text-decoration: none;

  &:hover,
  &.is-active {
    color: #fff;
  }
}

.b-comparison {
  width: 100%;

  >.container {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 0;
  }
}

.b-comparison__dots {
  span {
    content: '';
    position: absolute;
    width: 313px;
    height: 313px;
    background: url('../img/blue_dots2.png') no-repeat 0 0;
    background-size: contain;
    z-index: 1;

    &:nth-child(1) {
      left: -418px;
      top: 170px;
    }

    &:nth-child(2) {
      right: -398px;
      top: 420px;
    }
  }
}

.b-comparison__letter {
  position: absolute;
  width: 300px;
  height: 317px;
  background: url('../img/c_letter.png') no-repeat 0 0;
  background-size: contain;
  left: -75px;
  top: 166px;
  z-index: -1;

  @include rb(lg) {
    top: 122px;
  }

  @include rb(md) {
    width: 250px;
    height: 264px;
  }

  @include rb(sm) {
    width: 190px;
    height: 200px;
    left: -100px;
    top: 20px;
  }

  @include rb(xs) {
    right: 10px;
    left: auto;
    top: 0;
    width: 120px;
    height: 138px;
  }
}

.comparison-slider {
  width: 100%;
  position: relative;
  z-index: 10;
  padding-top: 30px;

  .swiper-container {
    overflow: visible;
  }

  .swiper-controls {
    left: 50%;
    margin-left: 165px;
    bottom: 0;

    @include rb(sm) {
      right: 50px;
      left: auto;
      width: 110px;
      margin-left: 0;
      bottom: 100px;

      .swiper-button-prev,
      .swiper-button-next {
        background: #fff;
        border-color: #fff;
      }
    }

    @include rb(xs) {
      right: auto;
      left: 15px;
      bottom: -70px;
    }
  }

  .container {
    position: relative;
  }

  @include rb(sm) {
    padding-top: 0;
  }

  @include rb(xs) {
    padding-bottom: 70px;
  }
}

.comparison-slide {
  position: relative;
  @include clearfix;
  min-height: 470px;

  .section-content__text {
    p {
      max-width: 250px;
    }

    @include rb(sm) {
      max-width: none;

      p {
        max-width: none;
      }
    }
  }
}

.comparison-slide__left {
  float: left;
  padding-left: 160px;
  padding-top: 25px;
  width: 50%;
  box-sizing: border-box;

  @include rb(lg) {
    padding-left: 100px;
  }

  @include rb(md) {
    padding-left: 50px;
  }

  @include rb(sm) {
    float: none;
    padding-left: 0;
    padding-top: 0;
    margin-bottom: 40px;
  }

  @include rb(xs) {
    width: 100%;
  }
}

.comparison-slide__right {
  float: right;
  width: 50%;
  padding-left: 330px;
  padding-top: 40px;
  box-sizing: border-box;

  @include rb(lg) {
    padding-left: 220px;
  }

  @include rb(sm) {
    padding-left: 15px;
    padding-left: 0;
    padding-top: 0;
    float: none;

    .section-content__text {
      color: $gray-4;
    }
  }

  @include rb(xs) {
    width: 100%;
  }
}

.comparison-slide__image {
  position: absolute;
  width: 445px;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  top: 50%;
  margin-left: 1px;
  margin-top: 39px;

  img {
    max-width: 100%;
    height: auto;
  }

  @include rb(lg) {
    width: 350px;
  }

  @include rb(sm) {
    left: auto;
    right: 0;
    top: 0;
    transform: translate(0);
    margin: 0;
  }

  @include rb(xs) {
    position: relative;
    width: 200px;
  }
}

.section--comparison {
  @include rb(sm) {
    &:before {
      width: 100%;
    }
  }
}

.catalog-top {
  min-height: 717px;
  width: 100%;
  padding-top: 100px;
  padding-bottom: 40px;

  .container {
    position: relative;
  }

  @include rb(lg) {
    min-height: 670px;
  }

  @include rb(md) {
    min-height: 600px;
  }

  @include rb(sm) {
    min-height: 0;
  }

  @include rb(xs) {
    padding-top: 0;
  }
}

.section--catalog {
  padding-bottom: 33px;
  position: relative;
  z-index: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 0;
  height: auto;
}

.b-about--catalog {
  z-index: 0;

  .section-content__text {
    max-width: 350px;
  }

  .section-content {
    max-width: 45%;

    @include rb(xs) {
      max-width: none;
    }
  }

  .section-content__text {
    p {
      max-width: 270px;
    }
  }
}

.catalog-item {
  width: 500px;
  max-height: 562px;
  display: inline-block;
  position: absolute;
  left: 50%;
  margin-left: 135px;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 40px;

  img {
    max-width: 500px;
    max-height: 562px;
    width: auto;
    height: auto;
  }

  @include rb(lg) {
    width: 350px;
    max-height: 450px;
    margin-left: 100px;
    margin-top: 0;

    img {
      max-width: 350px;
      max-height: 450px;
    }
  }

  @include rb(md) {
    margin-left: 80px;
  }

  @include rb(sm) {
    width: 220px;
    height: 280px;

    img {
      max-width: 220px;
      max-height: 280px;
    }
  }

  @include rb(xs) {
    position: relative;
    left: 0;
    top: 0;
    transform: translateY(0);
    margin: 0;
    margin-bottom: 30px;
  }
}

.b-about-dots {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  z-index: 0;

  span {
    position: absolute;
    width: 313px;
    height: 313px;
    background: url('../img/blue_dots2.png') no-repeat 0 0;
    background-size: contain;
    z-index: 1;

    &:nth-child(1) {
      right: -318px;
      top: 170px;
    }

    &:nth-child(2) {
      right: -418px;
      top: 470px;
    }
  }
}

.b-pluses {
  width: 100%;
}

.b-pluses__inner {
  max-width: 1200px;
  margin: 0 auto;

  .section-content {
    max-width: none;
  }

  .section-content__text {
    h2 {
      margin-bottom: 67px;
    }
  }
}

.b-pluses-dots {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
}

.pluses-list {
  display: flex;
  flex-wrap: wrap;
}

.pluses-item {
  width: 33.33%;
  padding-right: 20px;
  position: relative;
  margin-bottom: 66px;

  p {
    margin: 0;
    position: relative;
    padding-left: 80px;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      background-position: 0 0;
      background-repeat: no-repeat;
      background-size: contain;
      transform: translateY(-50%);
    }
  }

  &--item1 {
    p:before {
      width: 65px;
      height: 68px;
      background-image: url('../img/plus_icon1.png');
    }
  }

  &--item2 {
    p:before {
      width: 57px;
      height: 72px;
      background-image: url('../img/plus_icon2.png');
    }
  }

  &--item3 {
    p:before {
      width: 57px;
      height: 62px;
      background-image: url('../img/plus_icon3.png');
    }
  }

  &--item4 {
    p:before {
      width: 59px;
      height: 60px;
      background-image: url('../img/plus_icon4.png');
    }
  }

  &--item5 {
    p:before {
      width: 52px;
      height: 74px;
      background-image: url('../img/plus_icon5.png');
    }
  }

  @include rb(md) {
    width: 50%;
    margin-bottom: 50px;
  }

  @include rb(xs) {
    width: 100%;
    padding-right: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.about-factory__d-letter {
  background: url('../img/d_letter.png') no-repeat 0 0;
  background-size: contain;
  width: 301px;
  height: 307px;
  left: -70px;
  top: 50px;
  position: absolute;
  z-index: 10;

  @include rb(md) {
    width: 286px;
    height: 243px;
    left: -35px;
    top: -40px;
  }

  @include rb(sm) {
    width: 187px;
    height: 201px;
    top: -40px;
  }

  @include rb(xs) {
    left: auto;
    right: -30px;
    width: 150px;
    height: 161px;
    top: -20px;
  }
}

.catalog-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: -220px;

  @include rb(lg) {
    margin-bottom: -150px;
  }

  @include rb(md) {
    margin-bottom: -100px;
  }

  @include rb(md) {
    margin-bottom: -80px;
  }

  @media (max-width: 540px) {
    margin-bottom: -40px;
  }
}

.catalog-list-item {
  color: $gray-3;
  width: 50%;
  position: relative;
  margin-bottom: 220px;

  &:nth-child(3n + 3) {
    width: 100%;

    .catalog-list-item__inner {
      width: 50%;
      margin: 0 auto;
    }
  }

  @include rb(lg) {
    margin-bottom: 150px;
  }

  @include rb(md) {
    margin-bottom: 100px;
  }

  @include rb(md) {
    margin-bottom: 80px;
    width: 100%;

    &:nth-child(3n + 3) {
      .catalog-list-item__inner {
        width: 100%;
      }
    }
  }

  @media (max-width: 540px) {
    margin-bottom: 40px;
  }
}

.catalog-list-item__inner {
  position: relative;
  padding-left: 400px;

  @include rb(lg) {
    padding-left: 300px;
  }

  @include rb(md) {
    padding-left: 200px;
  }

  @media (max-width: 540px) {
    padding-left: 80px;
  }
}

.catalog-list-item__image {
  position: absolute;
  left: 40px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0;

  img {
    max-width: 319px;
    max-height: 384px;
    width: auto;
    height: auto;
  }

  @include rb(lg) {
    left: 30px;

    img {
      max-width: 220px;
      max-height: 280px;
    }
  }

  @include rb(md) {
    left: 20px;

    img {
      max-width: 150px;
      max-height: 200px;
    }
  }

  @media (max-width: 540px) {
    left: 0px;
    transform: translate(0);
    top: 0;

    img {
      max-width: 60px;
      max-height: 80px;
    }
  }
}

.catalog-list-container {
  width: 100%;

  .container {
    position: relative;
  }
}

.catalog-list-dots {
  span {
    position: absolute;
    width: 313px;
    height: 313px;
    background: url('../img/blue_dots2.png') no-repeat 0 0;
    background-size: contain;
    z-index: 1;

    &:nth-child(1) {
      left: -418px;
      top: 85px;
    }

    &:nth-child(2) {
      right: -298px;
      top: 390px;
    }

    &:nth-child(3) {
      left: -223px;
      bottom: 185px;
    }
  }
}

.catalog-item-popup {
  position: fixed;
  z-index: 1000;
  width: 50%;
  min-width: 960px;
  top: 0;
  right: 0;
  background: #fff;
  height: 100vh;
  padding-left: 170px;
  padding-top: 100px;
  padding-right: 60px;
  color: $gray-3;
  display: none;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100px;
    left: 0;
    bottom: 0;
    background: linear-gradient(to top, rgba(#fff, 1), rgba(#fff, 0));
    z-index: 1;
  }

  img {
    margin-bottom: 20px;
  }

  h3 {
    @include f-echoessans;
    font-weight: 500;
    font-size: 63px;
    line-height: 0.873016;
    margin: 0;
    margin-bottom: 20px;
    font-variant: small-caps;
    letter-spacing: -0.005em;
    margin-bottom: 20px;
  }

  @include rb(lg) {
    h3 {
      font-size: 59px;
    }
  }

  @include rb(md) {
    padding-left: 100px;
    padding-top: 100px;
    padding-right: 50px;

    h3 {
      font-size: 50px;
    }
  }

  @include rb(sm) {
    h3 {
      font-size: 40px;
    }
  }

  @media (max-width: 960px) {
    min-width: 0;
    width: 100%;
  }

  @include rb(xs) {
    padding-left: 30px;
    padding-top: 100px;
    padding-right: 10px;
    padding-bottom: 90px;

    h3 {
      font-size: 28px;
    }
  }
}

.catalog-item-popup__model {
  @include f-echoessans;
  text-transform: uppercase;
  font-weight: 20px;
  font-weight: 300;
  margin-bottom: 30px;
}

.catalog-item-popup__scroll {
  padding-right: 110px;
  position: relative;
  height: 100%;
  padding-bottom: 100px;

  @include rb(md) {
    padding-right: 50px;
  }

  @include rb(xs) {
    padding-right: 20px;
    height: calc(100% - 50px);
    padding-bottom: 0;
  }
}

.catalog-item-popup__square {
  span {
    width: 120px;
    height: 120px;
    position: absolute;
    background: $gray-4;
    z-index: 2;

    &:nth-child(1) {
      right: 0;
      top: 0;
    }

    &:nth-child(2) {
      right: 0;
      bottom: 0;
    }

    &:nth-child(3) {
      left: 0;
      bottom: 0;
    }
  }

  @include rb(md) {
    span {
      width: 80px;
      height: 80px;
    }
  }
}

.catalog-item-popup__close {
  position: absolute;
  width: 120px;
  height: 120px;
  left: 0;
  top: 0;
  cursor: pointer;
  background: $gray-4;
  border: 0;
  outline: none;

  &:before {
    content: '';
    position: absolute;
    width: 30px;
    height: 30px;
    background: url('../img/close.png') no-repeat 0 0;
    background-size: contain;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  @include rb(md) {
    width: 80px;
    height: 80px;

    &:before {
      width: 20px;
      height: 20px;
    }
  }
}
