.b-about--history {
  margin-top: 10px;
  padding-top: 63px;
  position: relative;

  .section-content__dots {

    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 313px;
      height: 313px;
      background: url('../img/white_dots.png') no-repeat 0 0;
      background-size: contain;
      z-index: 1;
    }

    &:before {
      right: -335px;
      top: -100px;
    }

    &:after {
      right: -445px;
      top: 210px;
    }
  }

  @media (max-width: 1580px) {
    padding-left: 40px;
    padding-right: 40px;
  }

  @include rb(lg) {
    .section-content__chzto {

      &:before,
      &:after {
        top: 0;
      }
    }

    .section-content {
      max-width: 395px;
    }
  }

  @include rb(md) {
    .section-content {
      max-width: 365px;
    }
  }

  @include rb(sm) {
    padding-top: 0;
    margin-top: 50px;
    padding-left: 30px;
    padding-right: 30px;

    .section-content {
      max-width: 275px;
    }
  }

  @include rb(xs) {
    margin-top: 0;

    .section-content {
      max-width: none;
    }
  }
}

.section--about2 {
  .section__chzto {

    &:before {
      background: url('../img/chz_white.png') no-repeat 0 0;
      background-size: contain;
    }

    &:after {
      background: url('../img/to_outline.png') no-repeat 0 0;
      background-size: contain;
    }
  }

  @include rb(xs) {
    .section__chzto {
      color: #fff;
      display: block;
    }
  }
}

.history-slider-dates {
  margin-bottom: 40px;
  max-width: 470px;

  .swiper-slide-thumb-active {
    .history-slider-dates__item {
      color: $gray-3;
      font-weight: 900;
    }
  }

  @include rb(xs) {
    max-width: none;
  }
}

.history-slider-dates__item {
  @include f-echoessans;
  font-weight: 500;
  font-size: 17px;
  color: $gray-3;
  cursor: pointer;
}

.history-slider-info {
  position: relative;
  max-width: 480px;

  p {
    margin: 0;
    max-width: 385px;
  }

  .swiper-button-prev {
    top: -72px;
    left: -65px;
    transform: translate(0);
    margin: 0;
  }

  .swiper-button-next {
    top: -72px;
    right: -65px;
    transform: translate(0);
    margin: 0;
  }

  @media (max-width: 1580px) {
    .swiper-button-prev {
      left: -40px;
    }

    .swiper-button-next {
      right: -40px;
    }
  }

  @include rb(sm) {
    .swiper-button-prev {
      top: -67px;
    }

    .swiper-button-next {
      top: -67px;
    }
  }

  @include rb(xs) {
    max-width: none;

    p {
      max-width: none;
    }
  }
}

.company-history-image {
  position: absolute;
  width: 50%;
  right: 0;
  top: -100px;
  bottom: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @include rb(sm) {
    top: 0;
  }

  @include rb(xs) {
    display: none;
  }
}

.about-factory__left {
  width: 50%;
  float: left;

  @include rb(xs) {
    width: 100%;
    float: none;
  }
}

.about-factory__right {
  float: right;
  width: 50%;
  padding-left: 250px;

  @include rb(lg) {
    padding-left: 100px;
  }

  @include rb(md) {
    padding-left: 50px;
  }

  @include rb(xs) {
    width: 100%;
    float: none;
    padding-left: 0;
  }
}

.about-factory {
  max-width: 100%;
  width: 100%;

  .container {
    position: relative;
    z-index: 0;
    display: flex;
    align-items: center;

    @include rb(xs) {
      display: block;
    }
  }

  .section-content {
    max-width: none;
    position: relative;
    z-index: 3;
  }

  .section-content__text {
    h2 {
      margin-bottom: 13px;
    }

    p {
      max-width: none;
      margin-bottom: 8px;
    }
  }
}

.about-factory2 {
  width: 100%;

  .section-content {
    max-width: 495px;

    h3 {
      max-width: 400px;
      margin-bottom: 15px;
    }

    p {
      max-width: none;
    }
  }
}

.about-factory-slider {
  position: relative;

  .swiper-controls {
    right: -58px;
    bottom: -58px;
  }

  .swiper-slide>div {
    overflow: hidden;
  }

  @include rb(md) {
    .swiper-controls {
      right: -20px;
      bottom: -20px;
    }
  }

  @include rb(xs) {
    margin-bottom: 80px;

    .swiper-controls {
      right: auto;
      left: 0;
      bottom: -60px;
    }
  }
}

.about-factory__letter {
  position: absolute;
  width: 286px;
  height: 307px;
  background: url('../img/p_letter.png') no-repeat 0 0;
  background-size: contain;
  left: -57px;
  top: -90px;
  z-index: 2;

  &--s {
    background: url('../img/s_letter.png') no-repeat 0 0;
    background-size: contain;
  }

  @include ra(md) {
    width: 303px;
    height: 317px;
    left: -72px;
  }

  @include rb(md) {
    width: 286px;
    height: 243px;
    left: -35px;
    top: -40px;
  }

  @include rb(sm) {
    width: 187px;
    height: 201px;
    top: 80px;

    &--s {
      top: -10px;
    }
  }

  @include rb(xs) {
    left: auto;
    right: -30px;
    width: 150px;
    height: 161px;
    top: -20px;
  }
}

.about-factory__dots {

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 313px;
    height: 303px;
    background: url('../img/blue_dots2.png') no-repeat 0 0;
    background-size: contain;
    z-index: -1;
    top: -90px;
    left: -388px;
  }

  &:after {
    top: 190px;
  }

  @include rb(xs) {
    display: none;
  }
}

.about-factory2 {
  .container {
    @include rb(xs) {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column-reverse;
    }
  }

  .about-factory__right {
    padding-left: 0;
  }

  .about-factory__left {
    padding-right: 50px;

    @include rb(xs) {
      padding-right: 0;
    }
  }

  .about-factory-slider {
    .swiper-controls--left {
      left: -58px;
      bottom: -58px;

      @include rb(md) {
        left: -20px;
        bottom: -20px;
      }

      @include rb(xs) {
        left: 0;
        bottom: -60px;
      }
    }
  }

  .about-factory__dots {
    &:before {
      top: auto;
      bottom: 15px;
      left: -388px;
    }

    &:after {
      left: 565px;
      top: 80px;
      z-index: 2;
    }

    @include rb(lg) {
      &:after {
        left: 440px;
      }
    }

    @include rb(md) {
      &:after {
        left: auto;
        right: -250px;
        top: -30px;
      }
    }
  }
}

.b-delivery-map {
  padding-top: 145px;
  width: 100%;

  .container {
    position: relative;
    z-index: 0;

    &:after {
      content: '';
      position: absolute;
      width: 313px;
      height: 303px;
      background: url('../img/blue_dots2.png') no-repeat 0 0;
      background-size: contain;
      z-index: -1;
      right: -448px;
      z-index: -1;
      top: -15px;
    }
  }

  @include rb(md) {
    padding-top: 0;
  }
}

.b-delivery-map__inner {
  @include clearfix;
}

.b-delivery-map__left {
  position: relative;
  float: left;
  z-index: 0;

  @include rb(xs) {
    float: none;
    padding-top: 220px;
  }
}

.b-delivery-map__right {
  float: right;
  position: relative;
  z-index: 1;

  @include rb(xs) {
    float: none;
  }
}

.b-delivery-map__image {
  position: absolute;
  width: 1195px;
  height: 718px;
  z-index: -1;
  left: 0;
  top: -310px;

  &:after {
    content: '';
    background: url('../img/dots_map.png') no-repeat 0 0;
    background-size: contain;
    position: absolute;
    left: 0;
    top: 0;
    width: 1195px;
    height: 718px;
  }

  &:before {
    content: '';
    position: absolute;
    background: url('../img/g_letter.png') no-repeat 0 0;
    background-size: contain;
    width: 316px;
    height: 318px;
    right: -88px;
    top: 39px;
    z-index: 1;

    @include rb(lg) {
      width: 250px;
      height: 252px;
      right: 0px;
    }

    @include rb(sm) {
      width: 170px;
      height: 172px;
      top: 60px;
    }
  }

  @include rb(md) {
    width: 800px;
    height: 481px;
    top: -110px;

    &:after {
      width: 800px;
      height: 481px;
    }
  }

  @include rb(sm) {
    width: 590px;
    height: 357px;
    left: -20px;
    top: -110px;

    &:after {
      width: 590px;
      height: 357px;
    }
  }

  @include rb(xs) {
    width: 100%;
    height: 295px;
    left: -20px;
    top: -80px;

    &:after {
      width: 490px;
      height: 295px;
    }

    &:before {
      width: 100px;
      height: 102px;
      right: -45px;
    }
  }
}

.b-clients2 {
  width: 100%;

  .container {
    position: relative;
    z-index: 0;

    &:after,
    &:before {
      content: '';
      position: absolute;
      width: 313px;
      height: 303px;
      background: url('../img/blue_dots2.png') no-repeat 0 0;
      background-size: contain;
      z-index: -1;
    }

    &:before {
      left: -475px;
      top: -100px;
    }

    &:after {
      right: -428px;
      bottom: -25px;
    }
  }

  .section-content {
    max-width: 1265px;
    margin: 0 auto;

    @include rb(lg) {
      max-width: 1104px;
    }

    @include rb(md) {
      max-width: 945px;
    }

    @include rb(sm) {
      max-width: 624px;
    }
  }
}

.b-clients2__letter {
  position: absolute;
  width: 300px;
  height: 317px;
  background: url('../img/c_letter.png') no-repeat 0 0;
  background-size: contain;
  left: -140px;
  top: -100px;
  z-index: -1;

  @include rb(md) {
    width: 250px;
    height: 264px;
  }

  @include rb(sm) {
    width: 190px;
    height: 200px;
    left: -100px;
    top: -55px;
  }

  @include rb(xs) {
    right: 10px;
    left: auto;
    top: 0;
    width: 120px;
    height: 138px;
  }
}

.clients-list {
  font-size: 0;
  margin-right: -16px;
  margin-bottom: -16px;
}

.client-item {
  display: inline-block;
  vertical-align: top;
  width: calc(12.5% - 17px);
  height: 144px;
  line-height: 144px;
  background: #fff;
  margin-right: 16px;
  margin-bottom: 16px;
  text-align: center;

  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    display: inline-block;
  }

  @include rb(lg) {
    height: 124px;
    line-height: 124px;
  }

  @include rb(md) {
    width: calc(25% - 16px);
  }

  @include rb(sm) {
    width: calc(33.33% - 16px);
  }

  @media (max-width: 480px) {
    width: calc(50% - 16px);
  }
}
