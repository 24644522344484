.footer {
  position: absolute;
  min-height: 120px;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 1;

  @media (max-width: 1100px) {
    position: relative;
  }
}

.footer-inner {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 120px;
  max-width: 1680px;
  width: 100%;
  background: $gray-4;
  z-index: 1;
  bottom: 0;
  padding: 32px 0;

  @media (max-width: 1700px) {
    padding-left: 120px;
    padding-right: 120px;

    .container {
      width: 100%;
    }
  }

  @include rb(lg) {
    padding-left: 80px;
    padding-right: 80px;
  }

  @media (max-width: 1100px) {
    position: relative;
    transform: translate(0);
    left: 0;
    height: auto;
    padding-bottom: 15px;
  }

  @include rb(xs) {
    padding-left: 0;
    padding-right: 0;
  }

  .container {
    @media (max-width: 1100px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      text-align: center;
    }
  }
}

.footer-inner__left {
  float: left;
  margin-right: 102px;
  padding-top: 2px;

  @media (max-width: 1430px) {
    margin-right: 40px;
  }

  @media (max-width: 1100px) {
    display: block;
    float: none;
    margin: 0 15px;
    margin-bottom: 20px;
  }
}

.footer__contacts {
  float: left;
  border-left: 1px solid $gray-5;
  border-right: 1px solid $gray-5;
  padding-left: 86px;
  padding-right: 96px;
  padding-top: 5px;

  a {
    color: $gray-5;
    text-decoration: none;

    &:hover {
      color: #fff;
    }
  }

  @media (max-width: 1430px) {
    padding-left: 40px;
    padding-right: 40px;
  }

  @media (max-width: 1100px) {
    float: none;
    border: 0;
    padding: 0;
    margin: 0 15px;
    margin-bottom: 20px;
  }
}

.footer__design {
  float: right;
  color: $gray-5;
  padding-top: 2px;
  line-height: 19px;

  p {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    margin-right: 35px;
  }

  img {
    max-width: 142px;
    display: inline-block;
    vertical-align: middle;
  }

  @media (max-width: 1100px) {
    float: none;
    margin-top: -15px;
    margin-bottom: -15px;

    p {
      margin: 15px;
    }

    img {
      margin: 15px;
    }
  }
}

.copyright,
.footer__address {
  display: block;
  font-size: 17px;
  line-height: 19px;
  color: $gray-5;
  font-style: normal;
  margin: 0;
  max-width: 299px;
}
