.arrow-link {
  text-transform: uppercase;
  @include f-echoessans;
  font-weight: 900;
  font-size: 17px;
  color: $blue-3;
  position: relative;
  text-decoration: none;
  display: inline-block;
  padding: 0;
  padding-right: 45px;
  outline: none;
  border: 0;
  background: none;
  transition: all 0.3s;

  &:before {
    content: '';
    position: absolute;
    width: 27px;
    height: 21px;
    right: 0;
    top: 50%;
    margin-top: -13px;
    background: url('../img/arrow_right_blue.png') no-repeat 0 0;
    background-size: contain;
  }

  &:hover {
    color: $blue-2;
  }

  &--left {
    padding-right: 0;
    padding-left: 45px;

    &:before {
      right: auto;
      left: 0;
      background: url('../img/arrow_left_blue.png') no-repeat 0 0;
      background-size: contain;
    }
  }
}

.download-link {
  text-transform: uppercase;
  @include f-echoessans;
  font-weight: 900;
  font-size: 17px;
  color: $blue-3;
  position: relative;
  text-decoration: none;
  display: inline-block;
  padding: 0;
  padding-right: 43px;
  outline: none;
  border: 0;
  background: none;
  transition: all 0.3s;

  &:before {
    content: '';
    position: absolute;
    width: 36px;
    height: 33px;
    right: 0;
    top: 50%;
    margin-top: -18px;
    background: url('../img/download.png') no-repeat 0 0;
    background-size: contain;
  }

  &:hover {
    color: $blue-2;
  }

}

.swiper-button-prev,
.swiper-button-next {
  width: 120px;
  height: 120px;
  top: auto;
  transform: translate(0);
  border: 1px solid $blue-3;
  margin: 0;
  bottom: 0;
  background: none;
  transition: border-color 0.3s, background-color 0.3s;
  outline: none;

  &:after {
    display: none;
  }

  &:before {
    content: '';
    position: absolute;
    width: 29px;
    height: 42px;
    top: 50%;
    margin-top: -21px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 0 0;
    left: 50%;
    margin-left: -17px;
  }

  &:hover {
    border-color: $gray-5;
    background: $gray-5;
  }

  &--simple {
    border: 0;
    background: none;
    width: 29px;
    height: 42px;
    bottom: auto;
    top: 50%;
    margin-top: -21px;

    &:before {
      top: 0;
      left: 0;
      margin: 0;
    }

    &:hover {
      background: none;
    }
  }

  @include ra(xs) {
    &--white {
      border-color: #fff;
      background: #fff;

      &:hover {
        border-color: $gray-3;
        background: $gray-3;
      }
    }
  }

  @include rb(sm) {
    &:before {
      width: 20px;
      height: 30px;
      margin-top: -15px;
      margin-left: -13px;
    }

    &--simple {
      width: 20px;
      height: 30px;
      margin-top: -15px;

      &:before {
        margin: 0;
      }
    }
  }
}

.swiper-button-prev {
  &:before {
    background-image: url('../img/slider_arr_prev.png');
  }

  &:not(.swiper-button-prev--simple) {
    @include rb(md) {
      width: 80px;
      height: 80px;
    }
  }

  &:not(.swiper-button-prev--simple) {
    @include rb(sm) {
      width: 50px;
      height: 50px;
    }
  }
}

.swiper-button-next {
  &:before {
    background-image: url('../img/slider_arr_next.png');
    margin-left: -13px;
  }

  &--simple {
    &:before {
      margin: 0;
    }
  }

  &:not(.swiper-button-next--simple) {
    @include rb(md) {
      width: 80px;
      height: 80px;
    }
  }

  &:not(.swiper-button-next--simple) {
    @include rb(sm) {
      width: 50px;
      height: 50px;
    }
  }

  &:not(.swiper-button-next--simple) {
    @include rb(sm) {
      &:before {
        margin-left: -9px;
      }
    }
  }
}

.swiper-controls--left {
  position: absolute;
  bottom: 0;
  left: 0;

  .swiper-button-prev {
    left: 0;
  }

  .swiper-button-next {
    right: auto;
    left: 140px;
  }

  @include rb(md) {
    .swiper-button-next {
      left: 100px;
    }
  }

  @include rb(sm) {
    .swiper-button-next {
      left: 60px;
    }
  }
}

.swiper-controls--right {
  position: absolute;
  bottom: 0;
  right: 0;

  .swiper-button-prev {
    left: auto;
    right: 140px;
  }

  .swiper-button-next {
    right: 0;
  }

  @include rb(md) {
    .swiper-button-prev {
      right: 100px;
    }
  }

  @include rb(sm) {
    .swiper-button-prev {
      right: 60px;
    }
  }

  @include rb(xs) {
    .swiper-button-prev {
      left: 0;
      right: auto;
    }

    .swiper-button-next {
      left: 60px;
      right: auto;
    }
  }
}

.swiper-controls--block {
  position: relative;
  width: 260px;
  height: 120px;

  .swiper-button-next {
    right: 0;
  }

  .swiper-button-prev {
    left: 0;
  }


  @include rb(md) {
    width: 170px;
    height: 80px;
  }

  @include rb(sm) {
    width: 110px;
    height: 50px;
  }
}

// form
.form-group {
  margin-bottom: 8px;
  max-width: 304px;
}

.form-field {
  background: none;
  height: 65px;
  width: 100%;
  outline: none;
  color: #fff;
  text-transform: uppercase;
  @include f-echoessans;
  font-weight: 500;
  border: 0;
  border-bottom: 1px solid $gray-5;
  transition: all 0.3s;

  &::placeholder {
    color: $gray-5;
  }

  &:focus {
    border-bottom-color: #fff;

    &::placeholder {
      color: transparent;
    }
  }
}

.form__accept {
  padding-top: 13px;
}

label.checkbox {
  display: inline-block;
  max-width: 304px;
  cursor: pointer;
  word-break: break-all;
  margin-bottom: 17px;
  user-select: none;

  input {
    display: none;

    &:checked~span {
      color: #fff;

      a {
        color: #fff;
      }

      &:before {
        border-color: #fff;
      }

      &:after {
        opacity: 1;
      }
    }
  }

  a {
    color: $gray-5;
    text-decoration: none;
    font-weight: 700;

    &:hover {
      color: #fff;
      text-decoration: underline;
    }
  }

  span {
    font-size: 17px;
    line-height: 19px;
    font-weight: 300;
    display: block;
    padding-left: 26px;
    position: relative;
    color: $gray-5;
    transition: all 0.3s;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 3px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      border: 1px solid $gray-5;
      transition: all 0.3s;
    }

    &:after {
      content: '';
      position: absolute;
      left: 3px;
      top: 6px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #fff;
      opacity: 0;
      transition: all 0.3s;
    }
  }
}


.ps--active-x>.ps__rail-x,
.ps--active-y>.ps__rail-y {
  opacity: 1;
}

.ps__rail-y {
  width: 1px;
  right: 7px;

  &:hover,
  &:active {
    .ps__thumb-y {
      background: none !important;
    }
  }
}

.ps--active-x>.ps__rail-x,
.ps--active-y>.ps__rail-y {
  background: $blue-3;

  &:hover {
    background: $blue-3;
  }
}

.ps__thumb-y {
  width: 14px;
  height: 14px;
  left: -6px;
  right: auto;
  z-index: 1;
  background: none;
  border-radius: 0;

  &:after {
    content: "";
    position: absolute;
    width: 14px;
    height: 14px;
    background: url('../img/ico_scroll.png') no-repeat 0 0;
    background-size: contain;
    left: 0;
    top: 0;
    z-index: 2;
  }

  &:before {
    content: "";
    position: absolute;
    width: 9px;
    height: 9px;
    left: 1px;
    top: 3px;
    border-radius: 50%;
    background: #fff;
    z-index: 1;
  }

  &:hover {
    background: url('../img/ico_scroll.png') no-repeat 0 0;
    background-size: contain;
  }
}
