@font-face {
  font-family: 'Echoes Sans';
  src: url('../fonts/EchoesSans-Medium.woff2') format('woff2'),
    url('../fonts/EchoesSans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Echoes Sans';
  src: url('../fonts/EchoesSans-Light.woff2') format('woff2'),
    url('../fonts/EchoesSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Conqueror Sans';
  src: url('../fonts/ConquerorSansLight.woff2') format('woff2'),
    url('../fonts/ConquerorSansLight.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Conqueror Sans';
  src: url('../fonts/ConquerorSans-Bold.woff2') format('woff2'),
    url('../fonts/ConquerorSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Conqueror Sans';
  src: url('../fonts/ConquerorSansMedium.woff2') format('woff2'),
    url('../fonts/ConquerorSansMedium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Conqueror Sans';
  src: url('../fonts/ConquerorSans.woff2') format('woff2'),
    url('../fonts/ConquerorSans.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Echoes Sans';
  src: url('../fonts/EchoesSans.woff2') format('woff2'),
    url('../fonts/EchoesSans.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Echoes Sans';
  src: url('../fonts/EchoesSans-Black.woff2') format('woff2'),
    url('../fonts/EchoesSans-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Circe';
  src: url('../fonts/Circe-Light.woff2') format('woff2'),
    url('../fonts/Circe-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}


@mixin f-echoessans {
  font-family: 'Echoes Sans', sans-serif;
}

@mixin f-conquerorsans {
  font-family: 'Conqueror Sans', sans-serif;
}

@mixin f-circe {
  font-family: 'Circe', sans-serif;
}
