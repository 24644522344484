@import url(../../node_modules/normalize.css/normalize.css);
@import url(../../node_modules/swiper/dist/css/swiper.css);
@import url(../../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.css);
@import url(../../node_modules/perfect-scrollbar/css/perfect-scrollbar.css);
@font-face {
  font-family: 'Echoes Sans';
  src: url("../fonts/EchoesSans-Medium.woff2") format("woff2"), url("../fonts/EchoesSans-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Echoes Sans';
  src: url("../fonts/EchoesSans-Light.woff2") format("woff2"), url("../fonts/EchoesSans-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Conqueror Sans';
  src: url("../fonts/ConquerorSansLight.woff2") format("woff2"), url("../fonts/ConquerorSansLight.woff") format("woff");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Conqueror Sans';
  src: url("../fonts/ConquerorSans-Bold.woff2") format("woff2"), url("../fonts/ConquerorSans-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Conqueror Sans';
  src: url("../fonts/ConquerorSansMedium.woff2") format("woff2"), url("../fonts/ConquerorSansMedium.woff") format("woff");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Conqueror Sans';
  src: url("../fonts/ConquerorSans.woff2") format("woff2"), url("../fonts/ConquerorSans.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Echoes Sans';
  src: url("../fonts/EchoesSans.woff2") format("woff2"), url("../fonts/EchoesSans.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Echoes Sans';
  src: url("../fonts/EchoesSans-Black.woff2") format("woff2"), url("../fonts/EchoesSans-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'Circe';
  src: url("../fonts/Circe-Light.woff2") format("woff2"), url("../fonts/Circe-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal; }

* {
  box-sizing: inherit; }
  *:before, *:after {
    box-sizing: inherit; }

iframe {
  border: none !important; }

img,
video,
audio {
  max-width: 100%;
  height: auto; }

button,
input,
select,
textarea {
  appearance: none;
  border-radius: 0; }

input::-ms-clear,
input::-ms-reveal {
  display: none;
  width: 0;
  height: 0; }

select::-ms-expand {
  display: none; }

label,
button,
select {
  cursor: pointer; }

html {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

body {
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  background-color: #fff;
  color: #000;
  background: #3c3c3c;
  color: #fff;
  font-family: 'Conqueror Sans', sans-serif;
  font-size: 17px;
  line-height: 1.117647;
  font-weight: 300;
  min-width: 320px; }

.page__layout {
  position: relative; }

main.main {
  position: relative; }

.container {
  margin: 0 auto;
  padding: 0 15px; }
  @media (max-width: 754px) {
    .container {
      width: 100%; } }
  @media (min-width: 755px) {
    .container {
      width: 740px; } }
  @media (min-width: 1007px) {
    .container {
      width: 992px; } }
  @media (min-width: 1215px) {
    .container {
      width: 1200px; } }
  @media (min-width: 1470px) {
    .container {
      width: 1455px; } }

p,
ul,
ol,
address,
figure,
blockquote,
table {
  margin: 0;
  margin-bottom: 20px; }

blockquote {
  display: block;
  padding-left: 15px; }

a {
  transition: color 0.3s;
  text-decoration: none;
  color: #079fad; }
  a:hover {
    text-decoration: none;
    color: #8edbff; }

ul,
ol {
  padding: 0;
  list-style-position: inside; }
  ul li,
  ol li {
    margin-bottom: 10px; }
    ul li:last-child,
    ol li:last-child {
      margin-bottom: 0; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  margin-bottom: 1em; }

::selection {
  background-color: rgba(255, 255, 255, 0.1) !important; }

@media (max-width: 754px) {
  .rellax {
    transform: translate3d(0, 0, 0) !important; } }

.arrow-link {
  text-transform: uppercase;
  font-family: 'Echoes Sans', sans-serif;
  font-weight: 900;
  font-size: 17px;
  color: #8edbff;
  position: relative;
  text-decoration: none;
  display: inline-block;
  padding: 0;
  padding-right: 45px;
  outline: none;
  border: 0;
  background: none;
  transition: all 0.3s; }
  .arrow-link:before {
    content: '';
    position: absolute;
    width: 27px;
    height: 21px;
    right: 0;
    top: 50%;
    margin-top: -13px;
    background: url("../img/arrow_right_blue.png") no-repeat 0 0;
    background-size: contain; }
  .arrow-link:hover {
    color: #079fad; }
  .arrow-link--left {
    padding-right: 0;
    padding-left: 45px; }
    .arrow-link--left:before {
      right: auto;
      left: 0;
      background: url("../img/arrow_left_blue.png") no-repeat 0 0;
      background-size: contain; }

.download-link {
  text-transform: uppercase;
  font-family: 'Echoes Sans', sans-serif;
  font-weight: 900;
  font-size: 17px;
  color: #8edbff;
  position: relative;
  text-decoration: none;
  display: inline-block;
  padding: 0;
  padding-right: 43px;
  outline: none;
  border: 0;
  background: none;
  transition: all 0.3s; }
  .download-link:before {
    content: '';
    position: absolute;
    width: 36px;
    height: 33px;
    right: 0;
    top: 50%;
    margin-top: -18px;
    background: url("../img/download.png") no-repeat 0 0;
    background-size: contain; }
  .download-link:hover {
    color: #079fad; }

.swiper-button-prev,
.swiper-button-next {
  width: 120px;
  height: 120px;
  top: auto;
  transform: translate(0);
  border: 1px solid #8edbff;
  margin: 0;
  bottom: 0;
  background: none;
  transition: border-color 0.3s, background-color 0.3s;
  outline: none; }
  .swiper-button-prev:after,
  .swiper-button-next:after {
    display: none; }
  .swiper-button-prev:before,
  .swiper-button-next:before {
    content: '';
    position: absolute;
    width: 29px;
    height: 42px;
    top: 50%;
    margin-top: -21px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 0 0;
    left: 50%;
    margin-left: -17px; }
  .swiper-button-prev:hover,
  .swiper-button-next:hover {
    border-color: #616161;
    background: #616161; }
  .swiper-button-prev--simple,
  .swiper-button-next--simple {
    border: 0;
    background: none;
    width: 29px;
    height: 42px;
    bottom: auto;
    top: 50%;
    margin-top: -21px; }
    .swiper-button-prev--simple:before,
    .swiper-button-next--simple:before {
      top: 0;
      left: 0;
      margin: 0; }
    .swiper-button-prev--simple:hover,
    .swiper-button-next--simple:hover {
      background: none; }
  @media (min-width: 755px) {
    .swiper-button-prev--white,
    .swiper-button-next--white {
      border-color: #fff;
      background: #fff; }
      .swiper-button-prev--white:hover,
      .swiper-button-next--white:hover {
        border-color: #3c3c3c;
        background: #3c3c3c; } }
  @media (max-width: 1006px) {
    .swiper-button-prev:before,
    .swiper-button-next:before {
      width: 20px;
      height: 30px;
      margin-top: -15px;
      margin-left: -13px; }
    .swiper-button-prev--simple,
    .swiper-button-next--simple {
      width: 20px;
      height: 30px;
      margin-top: -15px; }
      .swiper-button-prev--simple:before,
      .swiper-button-next--simple:before {
        margin: 0; } }

.swiper-button-prev:before {
  background-image: url("../img/slider_arr_prev.png"); }

@media (max-width: 1214px) {
  .swiper-button-prev:not(.swiper-button-prev--simple) {
    width: 80px;
    height: 80px; } }

@media (max-width: 1006px) {
  .swiper-button-prev:not(.swiper-button-prev--simple) {
    width: 50px;
    height: 50px; } }

.swiper-button-next:before {
  background-image: url("../img/slider_arr_next.png");
  margin-left: -13px; }

.swiper-button-next--simple:before {
  margin: 0; }

@media (max-width: 1214px) {
  .swiper-button-next:not(.swiper-button-next--simple) {
    width: 80px;
    height: 80px; } }

@media (max-width: 1006px) {
  .swiper-button-next:not(.swiper-button-next--simple) {
    width: 50px;
    height: 50px; } }

@media (max-width: 1006px) {
  .swiper-button-next:not(.swiper-button-next--simple):before {
    margin-left: -9px; } }

.swiper-controls--left {
  position: absolute;
  bottom: 0;
  left: 0; }
  .swiper-controls--left .swiper-button-prev {
    left: 0; }
  .swiper-controls--left .swiper-button-next {
    right: auto;
    left: 140px; }
  @media (max-width: 1214px) {
    .swiper-controls--left .swiper-button-next {
      left: 100px; } }
  @media (max-width: 1006px) {
    .swiper-controls--left .swiper-button-next {
      left: 60px; } }

.swiper-controls--right {
  position: absolute;
  bottom: 0;
  right: 0; }
  .swiper-controls--right .swiper-button-prev {
    left: auto;
    right: 140px; }
  .swiper-controls--right .swiper-button-next {
    right: 0; }
  @media (max-width: 1214px) {
    .swiper-controls--right .swiper-button-prev {
      right: 100px; } }
  @media (max-width: 1006px) {
    .swiper-controls--right .swiper-button-prev {
      right: 60px; } }
  @media (max-width: 754px) {
    .swiper-controls--right .swiper-button-prev {
      left: 0;
      right: auto; }
    .swiper-controls--right .swiper-button-next {
      left: 60px;
      right: auto; } }

.swiper-controls--block {
  position: relative;
  width: 260px;
  height: 120px; }
  .swiper-controls--block .swiper-button-next {
    right: 0; }
  .swiper-controls--block .swiper-button-prev {
    left: 0; }
  @media (max-width: 1214px) {
    .swiper-controls--block {
      width: 170px;
      height: 80px; } }
  @media (max-width: 1006px) {
    .swiper-controls--block {
      width: 110px;
      height: 50px; } }

.form-group {
  margin-bottom: 8px;
  max-width: 304px; }

.form-field {
  background: none;
  height: 65px;
  width: 100%;
  outline: none;
  color: #fff;
  text-transform: uppercase;
  font-family: 'Echoes Sans', sans-serif;
  font-weight: 500;
  border: 0;
  border-bottom: 1px solid #616161;
  transition: all 0.3s; }
  .form-field::placeholder {
    color: #616161; }
  .form-field:focus {
    border-bottom-color: #fff; }
    .form-field:focus::placeholder {
      color: transparent; }

.form__accept {
  padding-top: 13px; }

label.checkbox {
  display: inline-block;
  max-width: 304px;
  cursor: pointer;
  word-break: break-all;
  margin-bottom: 17px;
  user-select: none; }
  label.checkbox input {
    display: none; }
    label.checkbox input:checked ~ span {
      color: #fff; }
      label.checkbox input:checked ~ span a {
        color: #fff; }
      label.checkbox input:checked ~ span:before {
        border-color: #fff; }
      label.checkbox input:checked ~ span:after {
        opacity: 1; }
  label.checkbox a {
    color: #616161;
    text-decoration: none;
    font-weight: 700; }
    label.checkbox a:hover {
      color: #fff;
      text-decoration: underline; }
  label.checkbox span {
    font-size: 17px;
    line-height: 19px;
    font-weight: 300;
    display: block;
    padding-left: 26px;
    position: relative;
    color: #616161;
    transition: all 0.3s; }
    label.checkbox span:before {
      content: '';
      position: absolute;
      left: 0;
      top: 3px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      border: 1px solid #616161;
      transition: all 0.3s; }
    label.checkbox span:after {
      content: '';
      position: absolute;
      left: 3px;
      top: 6px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #fff;
      opacity: 0;
      transition: all 0.3s; }

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  opacity: 1; }

.ps__rail-y {
  width: 1px;
  right: 7px; }
  .ps__rail-y:hover .ps__thumb-y, .ps__rail-y:active .ps__thumb-y {
    background: none !important; }

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  background: #8edbff; }
  .ps--active-x > .ps__rail-x:hover,
  .ps--active-y > .ps__rail-y:hover {
    background: #8edbff; }

.ps__thumb-y {
  width: 14px;
  height: 14px;
  left: -6px;
  right: auto;
  z-index: 1;
  background: none;
  border-radius: 0; }
  .ps__thumb-y:after {
    content: "";
    position: absolute;
    width: 14px;
    height: 14px;
    background: url("../img/ico_scroll.png") no-repeat 0 0;
    background-size: contain;
    left: 0;
    top: 0;
    z-index: 2; }
  .ps__thumb-y:before {
    content: "";
    position: absolute;
    width: 9px;
    height: 9px;
    left: 1px;
    top: 3px;
    border-radius: 50%;
    background: #fff;
    z-index: 1; }
  .ps__thumb-y:hover {
    background: url("../img/ico_scroll.png") no-repeat 0 0;
    background-size: contain; }

.header-content {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  padding: 51px 130px;
  overflow: hidden;
  z-index: 102; }
  @media (max-width: 1214px) {
    .header-content {
      padding: 32px 90px;
      height: 80px;
      overflow: visible; } }

.midnightHeader {
  height: 120px;
  left: 0;
  width: 100%;
  top: 0; }
  .midnightHeader:before, .midnightHeader:after {
    content: '';
    position: absolute;
    height: 100%;
    top: 0;
    width: 50%; }
  .midnightHeader:before {
    left: 0; }
  .midnightHeader:after {
    right: 0; }
  .midnightHeader.gray-white:before {
    background: #3c3c3c; }
  .midnightHeader.gray-white:after {
    background: #eaeaea; }
  @media (max-width: 754px) {
    .midnightHeader.gray-white:after {
      background: #3c3c3c; } }
  .midnightHeader.white-gray:after {
    background: #3c3c3c; }
  .midnightHeader.white-gray:before {
    background: #eaeaea; }
  .midnightHeader.white-image:before {
    background: #eaeaea; }
  .midnightHeader.gray:before {
    background: #3c3c3c; }
  .midnightHeader.gray:after {
    background: #3c3c3c; }
  .midnightHeader.white:before {
    background: #eaeaea; }
  .midnightHeader.white:after {
    background: #eaeaea; }

.header-bg {
  position: fixed;
  z-index: 100;
  width: 100%;
  left: 0;
  top: 0;
  height: 0; }
  .header-bg span {
    height: 120px;
    display: block; }
  @media (max-width: 1214px) {
    .header-bg {
      display: none; }
      .header-bg span {
        height: 80px; } }

.logo {
  display: block;
  border: 0;
  outline: none;
  width: 120px;
  height: 120px;
  text-align: center;
  line-height: 120px;
  font-size: 0;
  padding: 22px;
  font-size: 0;
  background: #474747;
  transition: background-color 0.3s;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 102; }
  .logo:hover {
    background-color: #3c3c3c; }
  .logo img {
    max-width: 75px;
    max-height: 74px;
    width: auto;
    height: auto; }
  @media (max-width: 1214px) {
    .logo {
      width: 80px;
      height: 80px;
      padding: 6px; }
      .logo img {
        max-width: 50px;
        max-height: 50px; } }

.header-phone {
  position: absolute;
  right: 0;
  top: 0;
  height: 120px;
  padding: 53px 0;
  padding-right: 167px; }
  @media (max-width: 1214px) {
    .header-phone {
      height: auto;
      padding: 0;
      position: relative;
      display: inline-block;
      padding-left: 35px; } }

.header-phone__link {
  color: #616161;
  font-size: 17px;
  text-decoration: none;
  font-family: 'Echoes Sans', sans-serif;
  padding: 3px 8px;
  font-weight: 900;
  position: relative; }
  .header-phone__link:before {
    content: '';
    position: absolute;
    height: 2px;
    width: 0;
    bottom: -3px;
    left: 0;
    background: #616161;
    transition: all 0.3s; }
  .header-phone__link:hover {
    color: #616161; }
    .header-phone__link:hover:before {
      width: 100%; }
  @media (max-width: 1214px) {
    .header-phone__link {
      font-size: 30px; } }
  @media (max-width: 754px) {
    .header-phone__link {
      font-size: 21px; } }

.header-phone__square {
  width: 120px;
  height: 120px;
  background: #474747;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
  outline: none;
  border: 0;
  cursor: pointer;
  transition: background-color 0.3s; }
  .header-phone__square:hover {
    background-color: #3c3c3c; }
  .header-phone__square:before {
    content: '';
    position: absolute;
    width: 25px;
    height: 36px;
    background: url("../img/tel.png") no-repeat 0 0;
    background-size: contain;
    left: 50%;
    margin-left: -12px;
    top: 50%;
    margin-top: -18px; }
  @media (max-width: 1214px) {
    .header-phone__square {
      width: 20px;
      height: 29px;
      right: auto;
      left: 0;
      opacity: 0.3; }
      .header-phone__square:before {
        width: 20px;
        height: 29px;
        margin-left: -10px;
        margin-top: -15px; } }
  @media (max-width: 754px) {
    .header-phone__square {
      width: 16px;
      height: 22px; } }

.nav-list {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 0; }
  .nav-list li {
    display: inline-block;
    vertical-align: top;
    margin: 0;
    margin-right: 32px; }
    .nav-list li:last-child {
      margin-right: 0; }
  .nav-list a {
    color: #616161;
    text-transform: uppercase;
    font-family: 'Echoes Sans', sans-serif;
    font-weight: 900;
    padding: 3px 8px;
    font-size: 17px;
    text-decoration: none;
    position: relative; }
    .nav-list a:before {
      content: '';
      position: absolute;
      height: 2px;
      width: 0;
      bottom: -3px;
      left: 0;
      background: #616161;
      transition: all 0.3s; }
    .nav-list a:hover:before, .nav-list a.is-active:before {
      width: 100%; }
  @media (max-width: 1214px) {
    .nav-list {
      text-align: center;
      margin-bottom: 40px; }
      .nav-list li {
        display: block;
        margin: 0;
        margin-bottom: 30px; }
      .nav-list a {
        font-size: 30px; } }
  @media (max-width: 1006px) {
    .nav-list {
      margin-bottom: 30px; }
      .nav-list li {
        margin-bottom: 25px; }
      .nav-list a {
        font-size: 26px; } }
  @media (max-width: 754px) {
    .nav-list {
      margin-bottom: 25px; }
      .nav-list li {
        margin-bottom: 20px; }
      .nav-list a {
        font-size: 21px; } }

@media (min-width: 1215px) {
  .header__mobile {
    display: block !important; } }

@media (max-width: 1214px) {
  .header__mobile {
    text-align: center;
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    right: 0;
    padding: 20px;
    background: #3c3c3c;
    z-index: 101;
    display: none; }
    .header__mobile > div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 100%; } }

.header-mobile-toggle {
  width: 80px;
  height: 80px;
  background: #3c3c3c;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 101;
  outline: none;
  padding: 0;
  border: 0;
  cursor: pointer;
  transition: background-color 0.3s;
  display: none; }
  .header-mobile-toggle span {
    width: 41px;
    height: 4px;
    border-radius: 3px;
    background: #fff;
    margin: 0 auto;
    margin-bottom: 10px;
    display: block;
    transition: all 0.3s;
    transform-origin: 0 0; }
    .header-mobile-toggle span:last-child {
      margin-bottom: 0; }
  .header-mobile-toggle.is-active span:nth-child(1) {
    transform: rotate(45deg) translateX(7px) translateY(-6px); }
  .header-mobile-toggle.is-active span:nth-child(2) {
    opacity: 0; }
  .header-mobile-toggle.is-active span:nth-child(3) {
    transform: rotate(-45deg) translateX(4px) translateY(5px); }
  @media (max-width: 1214px) {
    .header-mobile-toggle {
      display: block; } }

.section {
  position: relative;
  min-height: 976px;
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 140px 0;
  z-index: 0; }
  .section .section__fix {
    min-height: inherit;
    font-size: 0;
    margin: -140px 0; }
  .section .container {
    height: auto; }
  @media (max-width: 1469px) {
    .section {
      padding: 100px 0;
      min-height: 850px; }
      .section .section__fix {
        margin: -100px 0; } }
  @media (max-width: 1214px) {
    .section {
      padding: 80px 0;
      min-height: 810px; }
      .section:after {
        width: 80px;
        height: 80px; }
      .section .section__fix {
        margin: -80px 0; } }
  @media (max-width: 1006px) {
    .section {
      max-height: none;
      height: auto;
      min-height: 0;
      padding: 60px 0; }
      .section .section__fix {
        display: none; }
      .section.section--about {
        padding-top: 100px; } }
  @media (max-width: 754px) {
    .section {
      min-height: 0;
      height: auto;
      max-height: none;
      padding: 50px 0; }
      .section .section__fix {
        margin: -50px 0; }
      .section:after {
        display: none; } }
  @media (max-width: 754px) {
    .section:first-child {
      padding-top: 100px; } }
  .section .swiper-container > .container {
    z-index: 100; }

.swiper-container .container {
  box-sizing: border-box; }

.section__bottom {
  height: 1px;
  bottom: 0;
  width: 100%;
  left: 0;
  position: absolute; }

.section--half-bg {
  position: relative;
  z-index: 0; }
  .section--half-bg:before {
    content: '';
    position: absolute;
    width: 50%;
    right: 0;
    top: 0;
    height: 100%;
    background: #eaeaea;
    z-index: -1; }
  @media (max-width: 754px) {
    .section--half-bg {
      background: #eaeaea; }
      .section--half-bg:before {
        display: none; }
      .section--half-bg .section-content__text {
        color: #3c3c3c; } }

.section--white-image {
  position: relative;
  z-index: 0; }
  .section--white-image:before {
    content: '';
    position: absolute;
    width: 50%;
    left: 0;
    top: 0;
    height: 100%;
    background: #eaeaea;
    z-index: -1; }
  @media (max-width: 754px) {
    .section--white-image {
      background: #eaeaea; }
      .section--white-image:before {
        display: none; } }

.section--half-bg-reverse {
  position: relative; }
  .section--half-bg-reverse:before {
    content: '';
    position: absolute;
    width: 50%;
    left: 0;
    top: 0;
    height: 100%;
    background: #eaeaea; }
  @media (max-width: 754px) {
    .section--half-bg-reverse {
      background: #eaeaea; }
      .section--half-bg-reverse:before {
        display: none; }
      .section--half-bg-reverse .section-content__text {
        color: #3c3c3c; } }

.section--light-bg {
  background: #eaeaea; }

.section-control__wrapper,
.section-square__wrapper {
  position: fixed;
  width: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  z-index: 100; }

.section-square__wrapper {
  left: auto;
  right: 0; }

.section-control {
  width: 120px;
  height: 120px;
  background: #474747;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 100;
  outline: none;
  border: 0;
  cursor: pointer;
  transition: background-color 0.3s; }
  .section-control:hover {
    background-color: #3c3c3c; }
  .section-control:before {
    content: '';
    position: absolute;
    width: 30px;
    height: 40px;
    left: 50%;
    margin-left: -15px;
    top: 50%;
    margin-top: -17px;
    background: url("../img/arrow_down.png") no-repeat 0 0;
    background-size: contain; }
  .section-control.section-control--up:before {
    background: url("../img/arrow_up.png") no-repeat 0 0;
    background-size: contain; }
  @media (max-width: 1214px) {
    .section-control {
      width: 80px;
      height: 80px; }
      .section-control:before {
        width: 24px;
        height: 30px;
        margin-left: -12px;
        margin-top: -15px; } }
  @media (max-width: 754px) {
    .section-control {
      display: none; } }

.section-square {
  width: 120px;
  height: 120px;
  background: #474747;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 100;
  text-align: center;
  padding: 31px 10px;
  color: #3c3c3c;
  font-family: 'Echoes Sans', sans-serif;
  font-weight: 500;
  font-size: 63px;
  cursor: default;
  padding-right: 3px; }
  @media (max-width: 1214px) {
    .section-square {
      width: 80px;
      height: 80px; } }
  @media (max-width: 754px) {
    .section-square {
      display: none; } }

.b-about {
  position: relative;
  z-index: 0; }

.section-content {
  max-width: 550px; }
  @media (max-width: 1469px) {
    .section-content {
      max-width: 520px; } }
  @media (max-width: 1214px) {
    .section-content {
      max-width: 450px; } }
  @media (max-width: 754px) {
    .section-content {
      max-width: none; } }

.section-content--gray .section-content__text {
  color: #3c3c3c; }

.section-content__text h2 {
  font-family: 'Echoes Sans', sans-serif;
  font-weight: 500;
  font-size: 63px;
  line-height: 0.873016;
  margin: 0;
  margin-bottom: 20px;
  font-variant: small-caps;
  letter-spacing: -0.005em;
  margin-bottom: 29px; }

.section-content__text h3 {
  font-family: 'Echoes Sans', sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 32px;
  font-size: 20px;
  line-height: 1.1;
  letter-spacing: 0.01em;
  max-width: 235px; }

.section-content__text h4 {
  font-family: 'Echoes Sans', sans-serif;
  font-weight: 500;
  font-size: 20px; }

.section-content__text h5 {
  font-family: 'Echoes Sans', sans-serif;
  font-weight: 300;
  font-size: 20px;
  line-height: 1.1;
  letter-spacing: 0.01em;
  margin-bottom: 20px; }

.section-content__text p {
  max-width: 310px;
  line-height: 19px; }
  .section-content__text p:last-child {
    margin-bottom: 0; }

@media (max-width: 1469px) {
  .section-content__text h2 {
    font-size: 59px; } }

@media (max-width: 1214px) {
  .section-content__text h2 {
    font-size: 50px; }
  .section-content__text h3 {
    font-size: 18px;
    max-width: 200px; } }

@media (max-width: 1006px) {
  .section-content__text h2 {
    font-size: 40px; }
  .section-content__text h3 {
    font-size: 16px; } }

@media (max-width: 754px) {
  .section-content__text h2 {
    font-size: 28px; }
  .section-content__text h3 {
    font-size: 15px; }
  .section-content__text p {
    max-width: none; } }

.section-content__detail {
  width: 492px;
  height: 564px;
  position: absolute;
  left: 50%;
  margin-left: -244px;
  z-index: -1;
  top: -36px; }
  .section-content__detail:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: url("../img/detail1.png") no-repeat 0 0;
    background-size: contain; }
  .section-content__detail.section-content__detail--mobile {
    display: none; }
  @media (max-width: 1469px) {
    .section-content__detail {
      width: 462px;
      height: 530px;
      margin-left: -231px; } }
  @media (max-width: 1214px) {
    .section-content__detail {
      width: 380px;
      height: 435px;
      margin-left: -190px; } }
  @media (max-width: 1006px) {
    .section-content__detail {
      width: 310px;
      height: 354px;
      margin-left: -155px; } }
  @media (max-width: 754px) {
    .section-content__detail {
      width: 280px;
      height: 334px;
      left: 0;
      display: none;
      position: relative;
      margin: 0;
      margin-bottom: 30px;
      top: 0; }
      .section-content__detail:before {
        background: url("../img/detail1_1.png") no-repeat 0 0;
        background-size: contain; }
      .section-content__detail.section-content__detail--mobile {
        display: block; } }

.section__chzto-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 427px; }
  @media (max-width: 1469px) {
    .section__chzto-wrapper {
      height: 327px; } }
  @media (max-width: 1214px) {
    .section__chzto-wrapper {
      height: 266px; } }
  @media (max-width: 1006px) {
    .section__chzto-wrapper {
      height: 0; } }

.section__chzto {
  font-size: 0;
  position: absolute;
  left: 0;
  width: 100%;
  top: 0; }
  .section__chzto:before, .section__chzto:after {
    content: '';
    position: absolute;
    top: 160px; }
  .section__chzto:before {
    background: url("../img/chz.png") no-repeat 0 0;
    background-size: contain;
    width: 51.1vw;
    max-width: 982px;
    height: 0;
    padding-bottom: 22.2%;
    right: 50%;
    margin-right: 14px; }
  .section__chzto:after {
    background: url("../img/to.png") no-repeat 0 0;
    background-size: contain;
    width: 34.5vw;
    max-width: 663px;
    height: 0;
    padding-bottom: 22.2%;
    left: 50%;
    margin-left: 16px; }
  @media (max-width: 1214px) {
    .section__chzto:before, .section__chzto:after {
      top: 100px; } }
  @media (max-width: 754px) {
    .section__chzto {
      font-family: 'Echoes Sans', sans-serif;
      letter-spacing: 0.01em;
      line-height: 1;
      color: #eaeaea;
      font-weight: 900;
      font-size: 130px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      z-index: 0;
      top: 60px; }
      .section__chzto:before, .section__chzto:after {
        display: none; } }

.section__chzto--text {
  font-family: 'Echoes Sans', sans-serif;
  font-size: 510px;
  letter-spacing: 0.01em;
  line-height: 1;
  color: #474747;
  position: absolute;
  z-index: -1;
  font-weight: 900;
  text-transform: uppercase;
  left: 50%;
  transform: translateX(-54%);
  top: 100px; }
  .section__chzto--text:before, .section__chzto--text:after {
    display: none; }
  @media (max-width: 1908px) {
    .section__chzto--text {
      font-size: 450px;
      transform: translateX(-54%); } }
  @media (max-width: 1469px) {
    .section__chzto--text {
      font-size: 400px; } }
  @media (max-width: 1214px) {
    .section__chzto--text {
      font-size: 320px;
      top: 80px; } }
  @media (max-width: 1006px) {
    .section__chzto--text {
      font-size: 267px; } }
  @media (max-width: 754px) {
    .section__chzto--text {
      font-size: 100px;
      left: 50%;
      transform: translateX(-50%);
      top: 80px; } }

.b-products .container {
  position: relative;
  z-index: 0; }

.b-products__letter {
  position: absolute;
  width: 286px;
  height: 307px;
  background: url("../img/p_letter.png") no-repeat 0 0;
  background-size: contain;
  left: 287px;
  top: 0; }
  @media (max-width: 1469px) {
    .b-products__letter {
      left: 100px; } }
  @media (max-width: 1214px) {
    .b-products__letter {
      left: 20px;
      width: 286px;
      height: 243px; } }
  @media (max-width: 1006px) {
    .b-products__letter {
      left: -100px;
      width: 187px;
      height: 201px; } }
  @media (max-width: 754px) {
    .b-products__letter {
      left: auto;
      right: -30px;
      width: 150px;
      height: 161px; } }

.b-products__dots {
  position: absolute;
  width: 573px;
  height: 613px;
  background: url("../img/blue_dots.png") no-repeat 0 0;
  background-size: contain;
  left: -398px;
  z-index: -1;
  top: 0; }
  @media (max-width: 1469px) {
    .b-products__dots {
      left: -558px; } }
  @media (max-width: 1214px) {
    .b-products__dots {
      left: -590px; } }
  @media (max-width: 1006px) {
    .b-products__dots {
      left: -690px; } }
  @media (max-width: 754px) {
    .b-products__dots {
      left: -690px; } }

.b-products-slider {
  position: relative;
  overflow: visible;
  max-width: 100vw;
  padding-top: 87px;
  padding-bottom: 87px; }
  .b-products-slider .swiper-controls {
    left: 287px;
    bottom: -9px; }
  @media (max-width: 1469px) {
    .b-products-slider .swiper-controls {
      left: 100px; } }
  @media (max-width: 1214px) {
    .b-products-slider .swiper-controls {
      left: 15px; } }
  @media (max-width: 1006px) {
    .b-products-slider {
      padding-top: 0;
      padding-bottom: 0; }
      .b-products-slider .swiper-controls {
        bottom: -40px; } }
  @media (max-width: 754px) {
    .b-products-slider {
      padding-bottom: 0; }
      .b-products-slider .swiper-controls {
        bottom: 0px; } }

.b-products-slide {
  position: relative;
  padding: 40px 0;
  display: flex; }
  .b-products-slide .container {
    position: relative;
    z-index: 0; }
  @media (max-width: 754px) {
    .b-products-slide {
      padding-top: 0;
      padding-bottom: 80px; }
      .b-products-slide .container {
        position: relative; } }

.b-products-slide__img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 492px;
  height: 562px;
  margin-top: 40px; }
  .b-products-slide__img img {
    max-width: 100%;
    max-height: 562px;
    height: auto;
    width: auto; }
  .b-products-slide__img.b-products-slide__img--mobile {
    display: none; }
  @media (max-width: 1469px) {
    .b-products-slide__img {
      height: 450px;
      width: 394px; }
      .b-products-slide__img img {
        max-height: 450px; } }
  @media (max-width: 1214px) {
    .b-products-slide__img {
      height: 350px;
      width: 306px; }
      .b-products-slide__img img {
        max-height: 350px; } }
  @media (max-width: 1006px) {
    .b-products-slide__img {
      left: 40px;
      margin-top: 0;
      transform: translate(0, -50%);
      height: 315px;
      width: 275px; }
      .b-products-slide__img img {
        max-height: 315px; } }
  @media (max-width: 754px) {
    .b-products-slide__img {
      position: relative;
      margin-bottom: 30px;
      left: 0;
      transform: translate(0);
      top: 0;
      display: none; }
      .b-products-slide__img.b-products-slide__img--mobile {
        display: block; } }

.b-products-slide__right {
  width: 50%;
  padding-left: 327px;
  box-sizing: border-box;
  float: right; }
  @media (max-width: 1469px) {
    .b-products-slide__right {
      padding-left: 220px; } }
  @media (max-width: 1214px) {
    .b-products-slide__right {
      padding-left: 190px; } }
  @media (max-width: 1006px) {
    .b-products-slide__right {
      padding-left: 40px; } }
  @media (max-width: 754px) {
    .b-products-slide__right {
      padding-left: 0;
      width: 100%;
      float: none; } }

.b-advantages {
  max-width: 100vw;
  width: 100%; }
  .b-advantages .container {
    position: relative; }
  .b-advantages > .container {
    z-index: 0; }

.b-advantages__letter {
  position: absolute;
  width: 365px;
  height: 307px;
  background: url("../img/a_letter.png") no-repeat 0 0;
  background-size: contain;
  z-index: -1;
  left: -102px;
  top: -126px; }
  @media (max-width: 1006px) {
    .b-advantages__letter {
      width: 265px;
      height: 223px;
      left: -124px;
      top: -50px; } }
  @media (max-width: 754px) {
    .b-advantages__letter {
      width: 150px;
      height: 124px;
      left: auto;
      top: -20px;
      right: -20px; } }

.b-advantages__dots:before, .b-advantages__dots:after {
  content: '';
  position: absolute;
  width: 313px;
  height: 303px;
  background: url("../img/blue_dots2.png") no-repeat 0 0;
  background-size: contain;
  z-index: -1; }

.b-advantages__dots:before {
  left: -427px;
  top: -125px; }

.b-advantages__dots:after {
  right: -318px;
  top: 105px; }

@media (max-width: 1006px) {
  .b-advantages__dots:before {
    left: -450px; } }

.b-advantages-slider {
  overflow: visible; }
  .b-advantages-slider .container {
    box-sizing: border-box; }
  .b-advantages-slider .swiper-controls {
    right: 141px;
    bottom: -31px; }
  @media (max-width: 1469px) {
    .b-advantages-slider .swiper-controls {
      right: 50px;
      bottom: 0; } }
  @media (max-width: 754px) {
    .b-advantages-slider .swiper-controls {
      right: auto;
      left: 15px; } }

.b-advantages-slide {
  position: relative;
  padding-bottom: 18px; }
  @media (max-width: 754px) {
    .b-advantages-slide {
      padding-bottom: 80px; } }

.b-advantages-slide__content {
  padding-left: 176px; }
  @media (max-width: 1469px) {
    .b-advantages-slide__content {
      padding-left: 0; } }

.b-advantages-slide__img {
  position: absolute;
  width: 686px;
  height: 493px;
  right: 84px;
  margin-top: -14px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: flex-end;
  align-items: center; }
  .b-advantages-slide__img.b-advantages-slide__img--mobile {
    display: none; }
  .b-advantages-slide__img img {
    max-width: 686px;
    max-height: 493px;
    height: auto;
    width: auto; }
  @media (max-width: 1469px) {
    .b-advantages-slide__img {
      width: 622px;
      height: 445px; }
      .b-advantages-slide__img img {
        max-width: 622px;
        max-height: 445px; } }
  @media (max-width: 1214px) {
    .b-advantages-slide__img {
      width: 503px;
      height: 361px; }
      .b-advantages-slide__img img {
        max-width: 503px;
        max-height: 361px; } }
  @media (max-width: 1006px) {
    .b-advantages-slide__img {
      max-width: 388px;
      max-height: 278px;
      right: -20px; }
      .b-advantages-slide__img img {
        max-width: 388px;
        max-height: 278px; } }
  @media (max-width: 754px) {
    .b-advantages-slide__img {
      display: none;
      position: relative;
      transform: translate(0);
      margin: 0;
      right: 0;
      top: 0;
      margin-bottom: 30px;
      max-width: 290px;
      max-height: 208px; }
      .b-advantages-slide__img img {
        max-width: 290px;
        max-height: 208px; }
      .b-advantages-slide__img.b-advantages-slide__img--mobile {
        display: block; } }

.b-clients {
  width: 100%; }
  .b-clients .container {
    display: flex;
    position: relative;
    z-index: 0; }
  .b-clients .section-content__text h2 {
    margin-bottom: 38px; }

.b-clients__letter {
  position: absolute;
  width: 300px;
  height: 317px;
  background: url("../img/c_letter.png") no-repeat 0 0;
  background-size: contain;
  left: -65px;
  top: -60px; }
  @media (max-width: 1214px) {
    .b-clients__letter {
      width: 250px;
      height: 264px; } }
  @media (max-width: 1006px) {
    .b-clients__letter {
      width: 210px;
      height: 222px; } }
  @media (max-width: 754px) {
    .b-clients__letter {
      right: 10px;
      left: auto;
      top: 0;
      width: 120px;
      height: 138px; } }

.b-clients__dots {
  position: absolute;
  width: 313px;
  height: 303px;
  background: url("../img/blue_dots2.png") no-repeat 0 0;
  background-size: contain;
  z-index: -1;
  top: -54px;
  left: -425px; }

.b-clients__map {
  position: absolute;
  width: 953px;
  height: 573px;
  background: url("../img/clients_map.png") no-repeat 0 0;
  background-size: contain;
  z-index: -1;
  top: 10px;
  left: -65px; }
  .b-clients__map.b-clients__map--mobile {
    display: none; }
  @media (max-width: 1469px) {
    .b-clients__map {
      width: 799px;
      height: 482px; } }
  @media (max-width: 1214px) {
    .b-clients__map {
      width: 674px;
      height: 406px; } }
  @media (max-width: 1006px) {
    .b-clients__map {
      width: 522px;
      height: 315px; } }
  @media (max-width: 754px) {
    .b-clients__map {
      background: url("../img/clients_map_white.png") no-repeat 0 0;
      background-size: contain;
      margin: 0;
      margin-bottom: 30px;
      margin-top: -30px;
      position: relative;
      width: 290px;
      height: 175px;
      top: 0;
      left: 0; }
      .b-clients__map.b-clients__map--mobile {
        display: block; } }

.b-clients__left {
  width: 50%; }
  @media (max-width: 754px) {
    .b-clients__left {
      display: none; } }

.b-clients__right {
  width: 50%;
  padding-left: 248px; }
  @media (max-width: 1630px) {
    .b-clients__right {
      padding-left: 100px; } }
  @media (max-width: 1469px) {
    .b-clients__right {
      padding-left: 150px; } }
  @media (max-width: 1214px) {
    .b-clients__right {
      padding-left: 50px; } }
  @media (max-width: 754px) {
    .b-clients__right {
      padding-left: 0;
      width: 100%;
      float: none; } }

.clients-slider {
  position: relative;
  margin-bottom: 37px;
  padding: 0 87px;
  margin-left: -87px;
  margin-right: -87px; }
  @media (max-width: 1630px) {
    .clients-slider {
      margin-right: 0;
      padding: 0 82px;
      margin-left: -82px; } }
  @media (max-width: 1469px) {
    .clients-slider {
      padding: 0 60px;
      margin-left: -60px; } }
  @media (max-width: 1214px) {
    .clients-slider {
      padding: 0 50px;
      margin-left: -50px; } }
  @media (max-width: 754px) {
    .clients-slider {
      margin-left: -15px;
      margin-right: -15px; } }

.clients-slide {
  width: 100%;
  height: 144px;
  text-align: center;
  line-height: 144px;
  background: #fff; }
  .clients-slide img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto; }
  @media (max-width: 1630px) {
    .clients-slide {
      line-height: 144px;
      height: 144px; } }
  @media (max-width: 1469px) {
    .clients-slide {
      line-height: 114px;
      height: 114px; } }

.b-contacts {
  width: 100%; }
  .b-contacts .container {
    position: relative;
    z-index: 0; }
    .b-contacts .container:after, .b-contacts .container:before {
      content: '';
      position: absolute;
      width: 313px;
      height: 303px;
      background: url("../img/blue_dots2.png") no-repeat 0 0;
      background-size: contain;
      z-index: 0; }
    .b-contacts .container:before {
      left: -426px;
      top: 291px; }
    .b-contacts .container:after {
      right: -313px;
      top: -30px; }
  @media (max-width: 754px) {
    .b-contacts {
      padding: 20px 0; } }
  .b-contacts .rellax {
    z-index: -2;
    position: absolute;
    left: 0;
    width: 100%; }

.b-contacts__chzto {
  font-family: 'Echoes Sans', sans-serif;
  font-size: 510px;
  letter-spacing: 0.01em;
  line-height: 1;
  color: #474747;
  position: absolute;
  z-index: -1;
  font-weight: 900;
  text-transform: uppercase;
  left: -284px;
  top: -105px;
  user-select: none; }
  @media (max-width: 1908px) {
    .b-contacts__chzto {
      font-size: 450px;
      left: -100px; } }
  @media (max-width: 1469px) {
    .b-contacts__chzto {
      font-size: 400px;
      left: -80px; } }
  @media (max-width: 1214px) {
    .b-contacts__chzto {
      font-size: 320px;
      left: -90px; } }
  @media (max-width: 1006px) {
    .b-contacts__chzto {
      font-size: 267px;
      left: -70px; } }
  @media (max-width: 754px) {
    .b-contacts__chzto {
      font-size: 100px;
      left: 50%;
      transform: translateX(-50%);
      top: -40px; } }

.b-contacts__inner {
  max-width: 942px;
  margin: 0 auto;
  padding-top: 90px; }
  .b-contacts__inner .section-content__text h2 {
    margin-bottom: 7px; }
  @media (max-width: 1469px) {
    .b-contacts__inner {
      padding-top: 75px; } }
  @media (max-width: 1214px) {
    .b-contacts__inner {
      padding-top: 60px; } }
  @media (max-width: 1006px) {
    .b-contacts__inner {
      padding-top: 40px; } }
  @media (max-width: 754px) {
    .b-contacts__inner {
      padding-top: 0; } }

.bottom-links {
  display: flex; }
  @media (max-width: 754px) {
    .bottom-links {
      display: block; } }

.bottom-link__image {
  position: absolute;
  z-index: -1;
  width: 100%;
  left: 0;
  top: 0;
  bottom: -100px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.7; }
  @media (max-width: 1006px) {
    .bottom-link__image {
      bottom: 0; } }

.bottom-link {
  padding-top: 69px;
  padding-bottom: 140px;
  width: 50%;
  position: relative;
  z-index: 0;
  min-height: 488px;
  display: flex;
  align-items: center;
  background: #000;
  overflow: hidden; }
  .bottom-link h3 {
    font-family: 'Echoes Sans', sans-serif;
    font-size: 63px;
    line-height: 0.849524;
    font-weight: 500;
    font-variant: small-caps;
    margin: 0;
    margin-bottom: 14px; }
  .bottom-link:first-child {
    justify-content: flex-end;
    text-align: right;
    padding-right: 330px; }
    @media (max-width: 1469px) {
      .bottom-link:first-child {
        padding-right: 150px; } }
    @media (max-width: 1214px) {
      .bottom-link:first-child {
        padding-right: 120px; } }
    @media (max-width: 1006px) {
      .bottom-link:first-child {
        padding-right: 60px; } }
  .bottom-link:last-child {
    padding-left: 330px; }
    @media (max-width: 1469px) {
      .bottom-link:last-child {
        padding-left: 150px; } }
    @media (max-width: 1214px) {
      .bottom-link:last-child {
        padding-left: 120px; } }
    @media (max-width: 1006px) {
      .bottom-link:last-child {
        padding-left: 60px; } }
  @media (max-width: 1214px) {
    .bottom-link {
      padding-top: 49px;
      padding-bottom: 140px;
      min-height: 400px; }
      .bottom-link h3 {
        font-size: 48px; } }
  @media (max-width: 1100px) {
    .bottom-link {
      padding-top: 39px;
      padding-bottom: 29px;
      min-height: 250px; }
      .bottom-link h3 {
        font-size: 40px; } }
  @media (max-width: 754px) {
    .bottom-link {
      width: 100%; }
      .bottom-link h3 {
        font-size: 30px; }
      .bottom-link:first-child {
        justify-content: center;
        text-align: center;
        padding-right: 15px; }
      .bottom-link:last-child {
        justify-content: center;
        text-align: center;
        padding-left: 15px; } }

@media (max-width: 754px) {
  .contacts-form .form-group {
    max-width: none; } }

.footer {
  position: absolute;
  min-height: 120px;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 1; }
  @media (max-width: 1100px) {
    .footer {
      position: relative; } }

.footer-inner {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 120px;
  max-width: 1680px;
  width: 100%;
  background: #474747;
  z-index: 1;
  bottom: 0;
  padding: 32px 0; }
  @media (max-width: 1700px) {
    .footer-inner {
      padding-left: 120px;
      padding-right: 120px; }
      .footer-inner .container {
        width: 100%; } }
  @media (max-width: 1469px) {
    .footer-inner {
      padding-left: 80px;
      padding-right: 80px; } }
  @media (max-width: 1100px) {
    .footer-inner {
      position: relative;
      transform: translate(0);
      left: 0;
      height: auto;
      padding-bottom: 15px; } }
  @media (max-width: 754px) {
    .footer-inner {
      padding-left: 0;
      padding-right: 0; } }
  @media (max-width: 1100px) {
    .footer-inner .container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      text-align: center; } }

.footer-inner__left {
  float: left;
  margin-right: 102px;
  padding-top: 2px; }
  @media (max-width: 1430px) {
    .footer-inner__left {
      margin-right: 40px; } }
  @media (max-width: 1100px) {
    .footer-inner__left {
      display: block;
      float: none;
      margin: 0 15px;
      margin-bottom: 20px; } }

.footer__contacts {
  float: left;
  border-left: 1px solid #616161;
  border-right: 1px solid #616161;
  padding-left: 86px;
  padding-right: 96px;
  padding-top: 5px; }
  .footer__contacts a {
    color: #616161;
    text-decoration: none; }
    .footer__contacts a:hover {
      color: #fff; }
  @media (max-width: 1430px) {
    .footer__contacts {
      padding-left: 40px;
      padding-right: 40px; } }
  @media (max-width: 1100px) {
    .footer__contacts {
      float: none;
      border: 0;
      padding: 0;
      margin: 0 15px;
      margin-bottom: 20px; } }

.footer__design {
  float: right;
  color: #616161;
  padding-top: 2px;
  line-height: 19px; }
  .footer__design p {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    margin-right: 35px; }
  .footer__design img {
    max-width: 142px;
    display: inline-block;
    vertical-align: middle; }
  @media (max-width: 1100px) {
    .footer__design {
      float: none;
      margin-top: -15px;
      margin-bottom: -15px; }
      .footer__design p {
        margin: 15px; }
      .footer__design img {
        margin: 15px; } }

.copyright,
.footer__address {
  display: block;
  font-size: 17px;
  line-height: 19px;
  color: #616161;
  font-style: normal;
  margin: 0;
  max-width: 299px; }

.b-about--history {
  margin-top: 10px;
  padding-top: 63px;
  position: relative; }
  .b-about--history .section-content__dots:before, .b-about--history .section-content__dots:after {
    content: '';
    position: absolute;
    width: 313px;
    height: 313px;
    background: url("../img/white_dots.png") no-repeat 0 0;
    background-size: contain;
    z-index: 1; }
  .b-about--history .section-content__dots:before {
    right: -335px;
    top: -100px; }
  .b-about--history .section-content__dots:after {
    right: -445px;
    top: 210px; }
  @media (max-width: 1580px) {
    .b-about--history {
      padding-left: 40px;
      padding-right: 40px; } }
  @media (max-width: 1469px) {
    .b-about--history .section-content__chzto:before, .b-about--history .section-content__chzto:after {
      top: 0; }
    .b-about--history .section-content {
      max-width: 395px; } }
  @media (max-width: 1214px) {
    .b-about--history .section-content {
      max-width: 365px; } }
  @media (max-width: 1006px) {
    .b-about--history {
      padding-top: 0;
      margin-top: 50px;
      padding-left: 30px;
      padding-right: 30px; }
      .b-about--history .section-content {
        max-width: 275px; } }
  @media (max-width: 754px) {
    .b-about--history {
      margin-top: 0; }
      .b-about--history .section-content {
        max-width: none; } }

.section--about2 .section__chzto:before {
  background: url("../img/chz_white.png") no-repeat 0 0;
  background-size: contain; }

.section--about2 .section__chzto:after {
  background: url("../img/to_outline.png") no-repeat 0 0;
  background-size: contain; }

@media (max-width: 754px) {
  .section--about2 .section__chzto {
    color: #fff;
    display: block; } }

.history-slider-dates {
  margin-bottom: 40px;
  max-width: 470px; }
  .history-slider-dates .swiper-slide-thumb-active .history-slider-dates__item {
    color: #3c3c3c;
    font-weight: 900; }
  @media (max-width: 754px) {
    .history-slider-dates {
      max-width: none; } }

.history-slider-dates__item {
  font-family: 'Echoes Sans', sans-serif;
  font-weight: 500;
  font-size: 17px;
  color: #3c3c3c;
  cursor: pointer; }

.history-slider-info {
  position: relative;
  max-width: 480px; }
  .history-slider-info p {
    margin: 0;
    max-width: 385px; }
  .history-slider-info .swiper-button-prev {
    top: -72px;
    left: -65px;
    transform: translate(0);
    margin: 0; }
  .history-slider-info .swiper-button-next {
    top: -72px;
    right: -65px;
    transform: translate(0);
    margin: 0; }
  @media (max-width: 1580px) {
    .history-slider-info .swiper-button-prev {
      left: -40px; }
    .history-slider-info .swiper-button-next {
      right: -40px; } }
  @media (max-width: 1006px) {
    .history-slider-info .swiper-button-prev {
      top: -67px; }
    .history-slider-info .swiper-button-next {
      top: -67px; } }
  @media (max-width: 754px) {
    .history-slider-info {
      max-width: none; }
      .history-slider-info p {
        max-width: none; } }

.company-history-image {
  position: absolute;
  width: 50%;
  right: 0;
  top: -100px;
  bottom: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }
  @media (max-width: 1006px) {
    .company-history-image {
      top: 0; } }
  @media (max-width: 754px) {
    .company-history-image {
      display: none; } }

.about-factory__left {
  width: 50%;
  float: left; }
  @media (max-width: 754px) {
    .about-factory__left {
      width: 100%;
      float: none; } }

.about-factory__right {
  float: right;
  width: 50%;
  padding-left: 250px; }
  @media (max-width: 1469px) {
    .about-factory__right {
      padding-left: 100px; } }
  @media (max-width: 1214px) {
    .about-factory__right {
      padding-left: 50px; } }
  @media (max-width: 754px) {
    .about-factory__right {
      width: 100%;
      float: none;
      padding-left: 0; } }

.about-factory {
  max-width: 100%;
  width: 100%; }
  .about-factory .container {
    position: relative;
    z-index: 0;
    display: flex;
    align-items: center; }
    @media (max-width: 754px) {
      .about-factory .container {
        display: block; } }
  .about-factory .section-content {
    max-width: none;
    position: relative;
    z-index: 3; }
  .about-factory .section-content__text h2 {
    margin-bottom: 13px; }
  .about-factory .section-content__text p {
    max-width: none;
    margin-bottom: 8px; }

.about-factory2 {
  width: 100%; }
  .about-factory2 .section-content {
    max-width: 495px; }
    .about-factory2 .section-content h3 {
      max-width: 400px;
      margin-bottom: 15px; }
    .about-factory2 .section-content p {
      max-width: none; }

.about-factory-slider {
  position: relative; }
  .about-factory-slider .swiper-controls {
    right: -58px;
    bottom: -58px; }
  .about-factory-slider .swiper-slide > div {
    overflow: hidden; }
  @media (max-width: 1214px) {
    .about-factory-slider .swiper-controls {
      right: -20px;
      bottom: -20px; } }
  @media (max-width: 754px) {
    .about-factory-slider {
      margin-bottom: 80px; }
      .about-factory-slider .swiper-controls {
        right: auto;
        left: 0;
        bottom: -60px; } }

.about-factory__letter {
  position: absolute;
  width: 286px;
  height: 307px;
  background: url("../img/p_letter.png") no-repeat 0 0;
  background-size: contain;
  left: -57px;
  top: -90px;
  z-index: 2; }
  .about-factory__letter--s {
    background: url("../img/s_letter.png") no-repeat 0 0;
    background-size: contain; }
  @media (min-width: 1215px) {
    .about-factory__letter {
      width: 303px;
      height: 317px;
      left: -72px; } }
  @media (max-width: 1214px) {
    .about-factory__letter {
      width: 286px;
      height: 243px;
      left: -35px;
      top: -40px; } }
  @media (max-width: 1006px) {
    .about-factory__letter {
      width: 187px;
      height: 201px;
      top: 80px; }
      .about-factory__letter--s {
        top: -10px; } }
  @media (max-width: 754px) {
    .about-factory__letter {
      left: auto;
      right: -30px;
      width: 150px;
      height: 161px;
      top: -20px; } }

.about-factory__dots:before, .about-factory__dots:after {
  content: '';
  position: absolute;
  width: 313px;
  height: 303px;
  background: url("../img/blue_dots2.png") no-repeat 0 0;
  background-size: contain;
  z-index: -1;
  top: -90px;
  left: -388px; }

.about-factory__dots:after {
  top: 190px; }

@media (max-width: 754px) {
  .about-factory__dots {
    display: none; } }

@media (max-width: 754px) {
  .about-factory2 .container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse; } }

.about-factory2 .about-factory__right {
  padding-left: 0; }

.about-factory2 .about-factory__left {
  padding-right: 50px; }
  @media (max-width: 754px) {
    .about-factory2 .about-factory__left {
      padding-right: 0; } }

.about-factory2 .about-factory-slider .swiper-controls--left {
  left: -58px;
  bottom: -58px; }
  @media (max-width: 1214px) {
    .about-factory2 .about-factory-slider .swiper-controls--left {
      left: -20px;
      bottom: -20px; } }
  @media (max-width: 754px) {
    .about-factory2 .about-factory-slider .swiper-controls--left {
      left: 0;
      bottom: -60px; } }

.about-factory2 .about-factory__dots:before {
  top: auto;
  bottom: 15px;
  left: -388px; }

.about-factory2 .about-factory__dots:after {
  left: 565px;
  top: 80px;
  z-index: 2; }

@media (max-width: 1469px) {
  .about-factory2 .about-factory__dots:after {
    left: 440px; } }

@media (max-width: 1214px) {
  .about-factory2 .about-factory__dots:after {
    left: auto;
    right: -250px;
    top: -30px; } }

.b-delivery-map {
  padding-top: 145px;
  width: 100%; }
  .b-delivery-map .container {
    position: relative;
    z-index: 0; }
    .b-delivery-map .container:after {
      content: '';
      position: absolute;
      width: 313px;
      height: 303px;
      background: url("../img/blue_dots2.png") no-repeat 0 0;
      background-size: contain;
      z-index: -1;
      right: -448px;
      z-index: -1;
      top: -15px; }
  @media (max-width: 1214px) {
    .b-delivery-map {
      padding-top: 0; } }

.b-delivery-map__inner:before, .b-delivery-map__inner:after {
  content: '';
  display: table; }

.b-delivery-map__inner:after {
  clear: both; }

.b-delivery-map__left {
  position: relative;
  float: left;
  z-index: 0; }
  @media (max-width: 754px) {
    .b-delivery-map__left {
      float: none;
      padding-top: 220px; } }

.b-delivery-map__right {
  float: right;
  position: relative;
  z-index: 1; }
  @media (max-width: 754px) {
    .b-delivery-map__right {
      float: none; } }

.b-delivery-map__image {
  position: absolute;
  width: 1195px;
  height: 718px;
  z-index: -1;
  left: 0;
  top: -310px; }
  .b-delivery-map__image:after {
    content: '';
    background: url("../img/dots_map.png") no-repeat 0 0;
    background-size: contain;
    position: absolute;
    left: 0;
    top: 0;
    width: 1195px;
    height: 718px; }
  .b-delivery-map__image:before {
    content: '';
    position: absolute;
    background: url("../img/g_letter.png") no-repeat 0 0;
    background-size: contain;
    width: 316px;
    height: 318px;
    right: -88px;
    top: 39px;
    z-index: 1; }
    @media (max-width: 1469px) {
      .b-delivery-map__image:before {
        width: 250px;
        height: 252px;
        right: 0px; } }
    @media (max-width: 1006px) {
      .b-delivery-map__image:before {
        width: 170px;
        height: 172px;
        top: 60px; } }
  @media (max-width: 1214px) {
    .b-delivery-map__image {
      width: 800px;
      height: 481px;
      top: -110px; }
      .b-delivery-map__image:after {
        width: 800px;
        height: 481px; } }
  @media (max-width: 1006px) {
    .b-delivery-map__image {
      width: 590px;
      height: 357px;
      left: -20px;
      top: -110px; }
      .b-delivery-map__image:after {
        width: 590px;
        height: 357px; } }
  @media (max-width: 754px) {
    .b-delivery-map__image {
      width: 100%;
      height: 295px;
      left: -20px;
      top: -80px; }
      .b-delivery-map__image:after {
        width: 490px;
        height: 295px; }
      .b-delivery-map__image:before {
        width: 100px;
        height: 102px;
        right: -45px; } }

.b-clients2 {
  width: 100%; }
  .b-clients2 .container {
    position: relative;
    z-index: 0; }
    .b-clients2 .container:after, .b-clients2 .container:before {
      content: '';
      position: absolute;
      width: 313px;
      height: 303px;
      background: url("../img/blue_dots2.png") no-repeat 0 0;
      background-size: contain;
      z-index: -1; }
    .b-clients2 .container:before {
      left: -475px;
      top: -100px; }
    .b-clients2 .container:after {
      right: -428px;
      bottom: -25px; }
  .b-clients2 .section-content {
    max-width: 1265px;
    margin: 0 auto; }
    @media (max-width: 1469px) {
      .b-clients2 .section-content {
        max-width: 1104px; } }
    @media (max-width: 1214px) {
      .b-clients2 .section-content {
        max-width: 945px; } }
    @media (max-width: 1006px) {
      .b-clients2 .section-content {
        max-width: 624px; } }

.b-clients2__letter {
  position: absolute;
  width: 300px;
  height: 317px;
  background: url("../img/c_letter.png") no-repeat 0 0;
  background-size: contain;
  left: -140px;
  top: -100px;
  z-index: -1; }
  @media (max-width: 1214px) {
    .b-clients2__letter {
      width: 250px;
      height: 264px; } }
  @media (max-width: 1006px) {
    .b-clients2__letter {
      width: 190px;
      height: 200px;
      left: -100px;
      top: -55px; } }
  @media (max-width: 754px) {
    .b-clients2__letter {
      right: 10px;
      left: auto;
      top: 0;
      width: 120px;
      height: 138px; } }

.clients-list {
  font-size: 0;
  margin-right: -16px;
  margin-bottom: -16px; }

.client-item {
  display: inline-block;
  vertical-align: top;
  width: calc(12.5% - 17px);
  height: 144px;
  line-height: 144px;
  background: #fff;
  margin-right: 16px;
  margin-bottom: 16px;
  text-align: center; }
  .client-item img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    display: inline-block; }
  @media (max-width: 1469px) {
    .client-item {
      height: 124px;
      line-height: 124px; } }
  @media (max-width: 1214px) {
    .client-item {
      width: calc(25% - 16px); } }
  @media (max-width: 1006px) {
    .client-item {
      width: calc(33.33% - 16px); } }
  @media (max-width: 480px) {
    .client-item {
      width: calc(50% - 16px); } }

.section--docs {
  padding-top: 120px;
  padding-bottom: 33px;
  position: relative;
  z-index: 0;
  height: auto;
  min-height: 0;
  align-items: stretch;
  flex-direction: column;
  justify-content: stretch; }
  @media (max-width: 1214px) {
    .section--docs {
      padding-top: 80px; } }
  @media (max-width: 1006px) {
    .section--docs {
      padding-bottom: 20px; } }
  .section--docs .section-content--mobile {
    padding: 0 15px; }
  .section--docs .rellax {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0; }

.section-tabs-container {
  position: relative;
  background: #303030;
  left: 150px;
  width: calc(100% - 300px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 40px;
  padding-top: 25px;
  min-height: 87px; }
  @media (max-width: 1214px) {
    .section-tabs-container {
      left: 90px;
      width: calc(100% - 180px); } }
  @media (max-width: 754px) {
    .section-tabs-container {
      width: calc(100% - 30px);
      left: 15px; } }

.section-tabs {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  align-items: top;
  flex-wrap: wrap; }
  .section-tabs li {
    width: 220px;
    margin: 0;
    margin-right: 20px;
    margin-bottom: 20px; }
    @media (max-width: 1469px) {
      .section-tabs li {
        width: 150px; } }
    .section-tabs li:last-child {
      margin-right: 20px;
      margin-bottom: 20px; }
  .section-tabs span {
    max-width: 140px;
    display: block; }

.section-tabs__link {
  font-size: 15px;
  line-height: 15px;
  text-decoration: none;
  color: #616161;
  font-family: 'Echoes Sans', sans-serif;
  font-weight: 400; }
  .section-tabs__link.is-active, .section-tabs__link:hover {
    color: #fff; }

.section-content--mobile {
  display: none; }
  @media (max-width: 754px) {
    .section-content--mobile {
      display: block; } }

.docs-sliders {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }
  @media (max-width: 754px) {
    .docs-sliders {
      position: relative;
      height: auto;
      padding: 0 15px; } }

.docs-slider {
  width: 100%;
  height: 100%; }
  .docs-slider .swiper-slide {
    width: 100%; }
  .docs-slider .swiper-container {
    overflow: visible;
    height: 100%; }
  .docs-slider .swiper-wrapper {
    height: 100%; }
  .docs-slider .swiper-slide {
    height: 100%; }
  .docs-slider .swiper-slide-active .doc-image__link {
    transform: translateX(-158px); }
    @media (max-width: 1469px) {
      .docs-slider .swiper-slide-active .doc-image__link {
        transform: translateX(-200px); } }
    @media (max-width: 1006px) {
      .docs-slider .swiper-slide-active .doc-image__link {
        transform: translateX(-130px); } }
    @media (max-width: 754px) {
      .docs-slider .swiper-slide-active .doc-image__link {
        transform: translateX(0); } }

.docs-container .container {
  position: relative; }
  .docs-container .container:before, .docs-container .container:after {
    content: '';
    display: table; }
  .docs-container .container:after {
    clear: both; }

.docs-content {
  float: right;
  width: 45%;
  box-sizing: border-box;
  padding-left: 95px;
  padding-top: 146px;
  position: relative;
  z-index: 10; }
  .docs-content .section-content__text {
    padding-top: 0; }
    .docs-content .section-content__text h3 {
      margin-bottom: 22px; }
    .docs-content .section-content__text h2 {
      margin-bottom: 9px; }
    .docs-content .section-content__text p {
      margin-bottom: 18px;
      max-width: 200px; }
  @media (max-width: 1469px) {
    .docs-content {
      width: 55%;
      padding-top: 100px; } }
  @media (max-width: 1006px) {
    .docs-content {
      padding-top: 50px;
      width: 60%; } }
  @media (max-width: 754px) {
    .docs-content {
      padding-top: 30px;
      padding-left: 0;
      width: 100%;
      float: none; }
      .docs-content .section-content:not(.section-content--mobile) .section-content__text h2,
      .docs-content .section-content:not(.section-content--mobile) .section-content__text h3 {
        display: none; } }

.doc-image__link {
  width: 464px;
  height: 100%;
  max-height: 660px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s; }
  .doc-image__link img {
    max-width: 464px;
    max-height: 660px;
    width: auto;
    height: auto;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    border-radius: 3px; }
  @media (max-width: 1469px) {
    .doc-image__link {
      width: 400px;
      height: 100%;
      max-height: 540px; }
      .doc-image__link img {
        max-width: 400px;
        max-height: 540px; } }
  @media (max-width: 1006px) {
    .doc-image__link {
      width: 234px;
      height: 100%;
      max-height: 330px; }
      .doc-image__link img {
        max-width: 234px;
        max-height: 330px; } }

.docs-content__download {
  margin-bottom: 81px; }
  @media (max-width: 1214px) {
    .docs-content__download {
      margin-bottom: 50px; } }
  @media (max-width: 1006px) {
    .docs-content__download {
      margin-bottom: 40px; } }

.doc-image {
  text-align: center;
  width: 100%;
  height: 100%; }
  @media (max-width: 754px) {
    .doc-image {
      text-align: left; } }

.docs-container__chzto {
  font-family: 'Echoes Sans', sans-serif;
  font-size: 510px;
  letter-spacing: 0.01em;
  line-height: 1;
  color: #fff;
  position: absolute;
  z-index: -1;
  font-weight: 900;
  text-transform: uppercase;
  left: 50%;
  transform: translateX(-59%);
  top: 94px; }
  @media (max-width: 1908px) {
    .docs-container__chzto {
      font-size: 450px;
      transform: translateX(-57%); } }
  @media (max-width: 1469px) {
    .docs-container__chzto {
      font-size: 400px;
      transform: translateX(-55%); } }
  @media (max-width: 1214px) {
    .docs-container__chzto {
      font-size: 320px;
      transform: translateX(-54%);
      top: 40px; } }
  @media (max-width: 1006px) {
    .docs-container__chzto {
      font-size: 267px;
      transform: translateX(-53%); } }
  @media (max-width: 754px) {
    .docs-container__chzto {
      font-size: 126px;
      transform: translateX(-50%);
      top: 60px; } }

.section-tabs-slider {
  width: 100%;
  overflow: visible;
  flex: 1 1 auto;
  display: flex;
  margin-bottom: 85px;
  min-height: 655px; }
  .section-tabs-slider .swiper-wrapper {
    height: auto; }
  .section-tabs-slider .swiper-slide {
    height: 100%; }
  @media (max-width: 1469px) {
    .section-tabs-slider {
      min-height: 540px; } }
  @media (max-width: 1214px) {
    .section-tabs-slider {
      min-height: 330px; } }
  @media (max-width: 1006px) {
    .section-tabs-slider {
      min-height: 0;
      margin-bottom: 50px; } }
  @media (max-width: 754px) {
    .section-tabs-slider {
      padding-bottom: 20px;
      margin-bottom: 0px; } }

.section--contacts {
  padding-top: 120px;
  padding-bottom: 33px;
  position: relative;
  z-index: auto;
  align-items: stretch;
  flex-direction: column;
  justify-content: stretch;
  height: auto;
  min-height: 0; }
  .section--contacts .section__chzto:before {
    background: url("../img/chz_white.png") no-repeat 0 0;
    background-size: contain; }
  .section--contacts .section__chzto:after {
    background: url("../img/to_outline.png") no-repeat 0 0;
    background-size: contain; }
  .section--contacts .section__chzto--half:after {
    display: none; }
  @media (max-width: 754px) {
    .section--contacts .section__chzto {
      display: block;
      color: #fff; } }

.contacts-item ul {
  padding: 0;
  margin: 0;
  list-style: none;
  margin-bottom: 20px; }
  .contacts-item ul li {
    margin-bottom: 6px; }
    .contacts-item ul li b {
      font-weight: 500; }

.b-contacts-info {
  display: flex;
  align-items: center;
  padding-bottom: 40px;
  min-height: 737px; }
  @media (max-width: 1214px) {
    .b-contacts-info {
      min-height: 637px; } }
  @media (max-width: 1214px) {
    .b-contacts-info {
      min-height: 0; } }
  .b-contacts-info .container {
    position: relative;
    z-index: 0; }

.map-container {
  position: absolute;
  width: 50%;
  right: 0;
  top: 0;
  height: 100%;
  overflow: hidden; }
  @media (max-width: 754px) {
    .map-container {
      position: relative;
      height: 400px;
      width: 100%;
      margin-bottom: 30px; } }

.contacts-bg-image {
  position: absolute;
  width: 50%;
  right: 0;
  top: 0;
  bottom: -150px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }
  @media (max-width: 1006px) {
    .contacts-bg-image {
      bottom: 0; } }
  @media (max-width: 754px) {
    .contacts-bg-image {
      display: none; } }

#map {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0; }

[class*="ymaps-2"][class*="-ground-pane"] {
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
  /* Firefox 3.5+ */
  -webkit-filter: grayscale(100%);
  /* Chrome 19+ & Safari 6+ */ }

.needs-popup {
  position: absolute;
  width: 50%;
  height: 100%;
  right: 0;
  top: 0;
  background: #3c3c3c;
  z-index: 103;
  display: none;
  padding: 155px 0;
  padding-bottom: 120px; }
  @media (max-width: 1214px) {
    .needs-popup {
      padding: 100px 0;
      padding-bottom: 100px; } }
  @media (max-width: 754px) {
    .needs-popup {
      width: 100%; } }
  .needs-popup h3 {
    text-transform: uppercase;
    font-family: 'Echoes Sans', sans-serif;
    font-size: 63px;
    line-height: 0.873016;
    margin-bottom: 20px;
    font-weight: 500; }
    @media (max-width: 1214px) {
      .needs-popup h3 {
        font-size: 52px; } }
    @media (max-width: 1006px) {
      .needs-popup h3 {
        font-size: 36px; } }
    @media (max-width: 754px) {
      .needs-popup h3 {
        font-size: 30px; } }
  .needs-popup ul {
    padding: 0;
    margin: 0;
    list-style: none; }
    .needs-popup ul li {
      margin-bottom: 20px; }

.needs-popup__inner {
  padding: 0 170px;
  overflow: auto;
  height: 100%;
  width: 100%; }
  @media (max-width: 1600px) {
    .needs-popup__inner {
      padding: 0 100px; } }
  @media (max-width: 1214px) {
    .needs-popup__inner {
      padding: 0 30px; } }

.needs-popup__close {
  position: absolute;
  width: 120px;
  height: 120px;
  left: 0;
  top: 0;
  cursor: pointer;
  background: #474747; }
  .needs-popup__close:before {
    content: "";
    position: absolute;
    width: 30px;
    height: 30px;
    background: url("../img/close.png") no-repeat 0 0;
    background-size: contain;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }
  @media (max-width: 1214px) {
    .needs-popup__close {
      width: 80px;
      height: 80px; }
      .needs-popup__close:before {
        width: 20px;
        height: 20px; } }

.needs-popup__square {
  position: absolute;
  width: 120px;
  height: 120px;
  background: #474747;
  z-index: -1; }
  .needs-popup__square--tr {
    top: 0;
    right: 0; }
  .needs-popup__square--br {
    bottom: 0;
    right: 0; }
  .needs-popup__square--bl {
    bottom: 0;
    left: 0; }
  @media (max-width: 1214px) {
    .needs-popup__square {
      width: 80px;
      height: 80px; } }

.director-photo {
  display: none;
  margin-bottom: 25px; }
  @media (max-width: 754px) {
    .director-photo {
      display: block; } }

.b-team {
  width: 100%; }

.team-list {
  display: flex;
  flex-wrap: wrap;
  margin-right: -300px;
  margin-bottom: -30px; }
  @media (max-width: 1640px) {
    .team-list {
      margin-right: 0; } }
  @media (max-width: 1480px) {
    .team-list {
      justify-content: center;
      margin-left: -20px;
      margin-right: -20px; } }

.team-item {
  display: flex;
  margin-bottom: 30px;
  align-items: center;
  margin-right: 60px; }
  .team-item:nth-child(6n + 3) {
    margin-left: 320px; }
    @media (max-width: 1640px) {
      .team-item:nth-child(6n + 3) {
        margin-left: 300px; } }
    @media (max-width: 1480px) {
      .team-item:nth-child(6n + 3) {
        margin-left: 0; } }
  .team-item:nth-child(6n + 4) {
    margin-left: 80px; }
    @media (max-width: 1640px) {
      .team-item:nth-child(6n + 4) {
        margin-left: 0; } }
  .team-item:nth-child(6n + 6) {
    margin-left: 480px; }
    @media (max-width: 1640px) {
      .team-item:nth-child(6n + 6) {
        margin-left: 300px; } }
    @media (max-width: 1480px) {
      .team-item:nth-child(6n + 6) {
        margin-left: 0; } }
  .team-item .section-content {
    max-width: 280px; }
  @media (max-width: 1480px) {
    .team-item {
      display: block;
      padding: 0 20px;
      margin-right: 0; } }

.team-item__left {
  max-width: 320px; }
  .team-item__left img {
    max-width: 100%;
    height: auto; }
  @media (max-width: 1850px) {
    .team-item__left {
      max-width: 300px; } }
  @media (max-width: 1480px) {
    .team-item__left {
      margin-bottom: 25px; } }

.team-item__right {
  padding-left: 80px; }
  @media (max-width: 1850px) {
    .team-item__right {
      padding-left: 40px; } }
  @media (max-width: 1480px) {
    .team-item__right {
      padding-left: 0; } }

.team-item__photo {
  font-size: 0;
  overflow: hidden; }

.section--catalog-slider {
  padding-bottom: 33px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: auto;
  min-height: 0; }
  @media (max-width: 1006px) {
    .section--catalog-slider {
      min-height: 500px; } }

.catalog-image-slider {
  left: 0;
  right: 0;
  top: 120px;
  bottom: 120px;
  position: absolute;
  text-align: center;
  overflow: visible; }
  @media (max-width: 1006px) {
    .catalog-image-slider {
      top: 80px; } }
  @media (max-width: 754px) {
    .catalog-image-slider {
      position: relative;
      top: 0;
      bottom: 0;
      height: 280px;
      text-align: left;
      margin-bottom: 30px;
      overflow: visible; } }
  .catalog-image-slider .swiper-slide {
    width: 100%;
    display: flex;
    align-items: center; }
  .catalog-image-slider .swiper-container {
    overflow: visible;
    height: 100%; }
  .catalog-image-slider .swiper-wrapper {
    height: 100%; }
  .catalog-image-slider .swiper-slide {
    height: 100%; }
  .catalog-image-slider .swiper-slide-active .catalog-slider-img {
    transform: translateX(-150px); }
    @media (max-width: 1469px) {
      .catalog-image-slider .swiper-slide-active .catalog-slider-img {
        transform: translateX(-200px); } }
    @media (max-width: 1006px) {
      .catalog-image-slider .swiper-slide-active .catalog-slider-img {
        transform: translateX(-130px); } }
    @media (max-width: 754px) {
      .catalog-image-slider .swiper-slide-active .catalog-slider-img {
        transform: translateX(0); } }

.catalog-slider-slide {
  width: 100%; }

.catalog-slider-img {
  width: 500px;
  height: 100%;
  max-height: 562px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s; }
  .catalog-slider-img img {
    max-width: 500px;
    max-height: 562px;
    width: auto;
    height: auto; }
  @media (max-width: 1469px) {
    .catalog-slider-img {
      width: 350px;
      height: 100%;
      max-height: 450px; }
      .catalog-slider-img img {
        max-width: 350px;
        max-height: 450px; } }
  @media (max-width: 1006px) {
    .catalog-slider-img {
      width: 220px;
      height: 100%;
      max-height: 280px; }
      .catalog-slider-img img {
        max-width: 220px;
        max-height: 280px; } }

.catalog-texts-container {
  width: 100%;
  min-height: 1px;
  position: relative;
  z-index: 1;
  margin-bottom: 50px;
  min-height: 668px; }
  @media (max-width: 1469px) {
    .catalog-texts-container {
      min-height: 568px; } }
  @media (max-width: 1006px) {
    .catalog-texts-container {
      min-height: 0; } }
  .catalog-texts-container .container {
    position: static; }

.catalog-texts {
  float: right;
  width: 45%;
  box-sizing: border-box;
  padding-left: 95px;
  padding-top: 146px;
  padding-bottom: 40px; }
  .catalog-texts .section-content__text h3 {
    margin-bottom: 15px; }
  .catalog-texts .section-content__text p {
    max-width: 280px; }
  @media (max-width: 1469px) {
    .catalog-texts {
      width: 55%;
      padding-top: 140px; } }
  @media (max-width: 1006px) {
    .catalog-texts {
      padding-top: 30px;
      width: 60%; } }
  @media (max-width: 754px) {
    .catalog-texts {
      padding-top: 30px;
      padding-left: 0;
      width: 100%;
      float: none; } }

.section-tabs-container--slider {
  padding: 0; }
  @media (max-width: 754px) {
    .section-tabs-container--slider {
      margin-left: -15px;
      width: 100%; } }

.swiper-button-next.swiper-button-lock ~ .swiper-container .swiper-wrapper {
  justify-content: center; }

.catalog-links-slider {
  width: 100%;
  padding: 27px 96px;
  text-align: center; }
  @media (max-width: 1469px) {
    .catalog-links-slider {
      padding-left: 60px;
      padding-right: 60px; } }
  .catalog-links-slider .swiper-slide {
    text-align: left; }
  .catalog-links-slider .swiper-button-prev {
    left: 17px; }
  .catalog-links-slider .swiper-button-next {
    right: 17px; }
  .catalog-links-slider .swiper-slide-thumb-active .catalog-menu-link {
    color: #fff; }

.catalog-menu-link {
  font-size: 15px;
  line-height: 16px;
  color: #616161;
  display: inline-block;
  font-family: 'Echoes Sans', sans-serif;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.3s;
  text-decoration: none; }
  .catalog-menu-link:hover, .catalog-menu-link.is-active {
    color: #fff; }

.b-comparison {
  width: 100%; }
  .b-comparison > .container {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 0; }

.b-comparison__dots span {
  content: '';
  position: absolute;
  width: 313px;
  height: 313px;
  background: url("../img/blue_dots2.png") no-repeat 0 0;
  background-size: contain;
  z-index: 1; }
  .b-comparison__dots span:nth-child(1) {
    left: -418px;
    top: 170px; }
  .b-comparison__dots span:nth-child(2) {
    right: -398px;
    top: 420px; }

.b-comparison__letter {
  position: absolute;
  width: 300px;
  height: 317px;
  background: url("../img/c_letter.png") no-repeat 0 0;
  background-size: contain;
  left: -75px;
  top: 166px;
  z-index: -1; }
  @media (max-width: 1469px) {
    .b-comparison__letter {
      top: 122px; } }
  @media (max-width: 1214px) {
    .b-comparison__letter {
      width: 250px;
      height: 264px; } }
  @media (max-width: 1006px) {
    .b-comparison__letter {
      width: 190px;
      height: 200px;
      left: -100px;
      top: 20px; } }
  @media (max-width: 754px) {
    .b-comparison__letter {
      right: 10px;
      left: auto;
      top: 0;
      width: 120px;
      height: 138px; } }

.comparison-slider {
  width: 100%;
  position: relative;
  z-index: 10;
  padding-top: 30px; }
  .comparison-slider .swiper-container {
    overflow: visible; }
  .comparison-slider .swiper-controls {
    left: 50%;
    margin-left: 165px;
    bottom: 0; }
    @media (max-width: 1006px) {
      .comparison-slider .swiper-controls {
        right: 50px;
        left: auto;
        width: 110px;
        margin-left: 0;
        bottom: 100px; }
        .comparison-slider .swiper-controls .swiper-button-prev,
        .comparison-slider .swiper-controls .swiper-button-next {
          background: #fff;
          border-color: #fff; } }
    @media (max-width: 754px) {
      .comparison-slider .swiper-controls {
        right: auto;
        left: 15px;
        bottom: -70px; } }
  .comparison-slider .container {
    position: relative; }
  @media (max-width: 1006px) {
    .comparison-slider {
      padding-top: 0; } }
  @media (max-width: 754px) {
    .comparison-slider {
      padding-bottom: 70px; } }

.comparison-slide {
  position: relative;
  min-height: 470px; }
  .comparison-slide:before, .comparison-slide:after {
    content: '';
    display: table; }
  .comparison-slide:after {
    clear: both; }
  .comparison-slide .section-content__text p {
    max-width: 250px; }
  @media (max-width: 1006px) {
    .comparison-slide .section-content__text {
      max-width: none; }
      .comparison-slide .section-content__text p {
        max-width: none; } }

.comparison-slide__left {
  float: left;
  padding-left: 160px;
  padding-top: 25px;
  width: 50%;
  box-sizing: border-box; }
  @media (max-width: 1469px) {
    .comparison-slide__left {
      padding-left: 100px; } }
  @media (max-width: 1214px) {
    .comparison-slide__left {
      padding-left: 50px; } }
  @media (max-width: 1006px) {
    .comparison-slide__left {
      float: none;
      padding-left: 0;
      padding-top: 0;
      margin-bottom: 40px; } }
  @media (max-width: 754px) {
    .comparison-slide__left {
      width: 100%; } }

.comparison-slide__right {
  float: right;
  width: 50%;
  padding-left: 330px;
  padding-top: 40px;
  box-sizing: border-box; }
  @media (max-width: 1469px) {
    .comparison-slide__right {
      padding-left: 220px; } }
  @media (max-width: 1006px) {
    .comparison-slide__right {
      padding-left: 15px;
      padding-left: 0;
      padding-top: 0;
      float: none; }
      .comparison-slide__right .section-content__text {
        color: #474747; } }
  @media (max-width: 754px) {
    .comparison-slide__right {
      width: 100%; } }

.comparison-slide__image {
  position: absolute;
  width: 445px;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  top: 50%;
  margin-left: 1px;
  margin-top: 39px; }
  .comparison-slide__image img {
    max-width: 100%;
    height: auto; }
  @media (max-width: 1469px) {
    .comparison-slide__image {
      width: 350px; } }
  @media (max-width: 1006px) {
    .comparison-slide__image {
      left: auto;
      right: 0;
      top: 0;
      transform: translate(0);
      margin: 0; } }
  @media (max-width: 754px) {
    .comparison-slide__image {
      position: relative;
      width: 200px; } }

@media (max-width: 1006px) {
  .section--comparison:before {
    width: 100%; } }

.catalog-top {
  min-height: 717px;
  width: 100%;
  padding-top: 100px;
  padding-bottom: 40px; }
  .catalog-top .container {
    position: relative; }
  @media (max-width: 1469px) {
    .catalog-top {
      min-height: 670px; } }
  @media (max-width: 1214px) {
    .catalog-top {
      min-height: 600px; } }
  @media (max-width: 1006px) {
    .catalog-top {
      min-height: 0; } }
  @media (max-width: 754px) {
    .catalog-top {
      padding-top: 0; } }

.section--catalog {
  padding-bottom: 33px;
  position: relative;
  z-index: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 0;
  height: auto; }

.b-about--catalog {
  z-index: 0; }
  .b-about--catalog .section-content__text {
    max-width: 350px; }
  .b-about--catalog .section-content {
    max-width: 45%; }
    @media (max-width: 754px) {
      .b-about--catalog .section-content {
        max-width: none; } }
  .b-about--catalog .section-content__text p {
    max-width: 270px; }

.catalog-item {
  width: 500px;
  max-height: 562px;
  display: inline-block;
  position: absolute;
  left: 50%;
  margin-left: 135px;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 40px; }
  .catalog-item img {
    max-width: 500px;
    max-height: 562px;
    width: auto;
    height: auto; }
  @media (max-width: 1469px) {
    .catalog-item {
      width: 350px;
      max-height: 450px;
      margin-left: 100px;
      margin-top: 0; }
      .catalog-item img {
        max-width: 350px;
        max-height: 450px; } }
  @media (max-width: 1214px) {
    .catalog-item {
      margin-left: 80px; } }
  @media (max-width: 1006px) {
    .catalog-item {
      width: 220px;
      height: 280px; }
      .catalog-item img {
        max-width: 220px;
        max-height: 280px; } }
  @media (max-width: 754px) {
    .catalog-item {
      position: relative;
      left: 0;
      top: 0;
      transform: translateY(0);
      margin: 0;
      margin-bottom: 30px; } }

.b-about-dots {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  z-index: 0; }
  .b-about-dots span {
    position: absolute;
    width: 313px;
    height: 313px;
    background: url("../img/blue_dots2.png") no-repeat 0 0;
    background-size: contain;
    z-index: 1; }
    .b-about-dots span:nth-child(1) {
      right: -318px;
      top: 170px; }
    .b-about-dots span:nth-child(2) {
      right: -418px;
      top: 470px; }

.b-pluses {
  width: 100%; }

.b-pluses__inner {
  max-width: 1200px;
  margin: 0 auto; }
  .b-pluses__inner .section-content {
    max-width: none; }
  .b-pluses__inner .section-content__text h2 {
    margin-bottom: 67px; }

.b-pluses-dots {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0; }

.pluses-list {
  display: flex;
  flex-wrap: wrap; }

.pluses-item {
  width: 33.33%;
  padding-right: 20px;
  position: relative;
  margin-bottom: 66px; }
  .pluses-item p {
    margin: 0;
    position: relative;
    padding-left: 80px; }
    .pluses-item p:before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      background-position: 0 0;
      background-repeat: no-repeat;
      background-size: contain;
      transform: translateY(-50%); }
  .pluses-item--item1 p:before {
    width: 65px;
    height: 68px;
    background-image: url("../img/plus_icon1.png"); }
  .pluses-item--item2 p:before {
    width: 57px;
    height: 72px;
    background-image: url("../img/plus_icon2.png"); }
  .pluses-item--item3 p:before {
    width: 57px;
    height: 62px;
    background-image: url("../img/plus_icon3.png"); }
  .pluses-item--item4 p:before {
    width: 59px;
    height: 60px;
    background-image: url("../img/plus_icon4.png"); }
  .pluses-item--item5 p:before {
    width: 52px;
    height: 74px;
    background-image: url("../img/plus_icon5.png"); }
  @media (max-width: 1214px) {
    .pluses-item {
      width: 50%;
      margin-bottom: 50px; } }
  @media (max-width: 754px) {
    .pluses-item {
      width: 100%;
      padding-right: 0; }
      .pluses-item:last-child {
        margin-bottom: 0; } }

.about-factory__d-letter {
  background: url("../img/d_letter.png") no-repeat 0 0;
  background-size: contain;
  width: 301px;
  height: 307px;
  left: -70px;
  top: 50px;
  position: absolute;
  z-index: 10; }
  @media (max-width: 1214px) {
    .about-factory__d-letter {
      width: 286px;
      height: 243px;
      left: -35px;
      top: -40px; } }
  @media (max-width: 1006px) {
    .about-factory__d-letter {
      width: 187px;
      height: 201px;
      top: -40px; } }
  @media (max-width: 754px) {
    .about-factory__d-letter {
      left: auto;
      right: -30px;
      width: 150px;
      height: 161px;
      top: -20px; } }

.catalog-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: -220px; }
  @media (max-width: 1469px) {
    .catalog-list {
      margin-bottom: -150px; } }
  @media (max-width: 1214px) {
    .catalog-list {
      margin-bottom: -100px; } }
  @media (max-width: 1214px) {
    .catalog-list {
      margin-bottom: -80px; } }
  @media (max-width: 540px) {
    .catalog-list {
      margin-bottom: -40px; } }

.catalog-list-item {
  color: #3c3c3c;
  width: 50%;
  position: relative;
  margin-bottom: 220px; }
  .catalog-list-item:nth-child(3n + 3) {
    width: 100%; }
    .catalog-list-item:nth-child(3n + 3) .catalog-list-item__inner {
      width: 50%;
      margin: 0 auto; }
  @media (max-width: 1469px) {
    .catalog-list-item {
      margin-bottom: 150px; } }
  @media (max-width: 1214px) {
    .catalog-list-item {
      margin-bottom: 100px; } }
  @media (max-width: 1214px) {
    .catalog-list-item {
      margin-bottom: 80px;
      width: 100%; }
      .catalog-list-item:nth-child(3n + 3) .catalog-list-item__inner {
        width: 100%; } }
  @media (max-width: 540px) {
    .catalog-list-item {
      margin-bottom: 40px; } }

.catalog-list-item__inner {
  position: relative;
  padding-left: 400px; }
  @media (max-width: 1469px) {
    .catalog-list-item__inner {
      padding-left: 300px; } }
  @media (max-width: 1214px) {
    .catalog-list-item__inner {
      padding-left: 200px; } }
  @media (max-width: 540px) {
    .catalog-list-item__inner {
      padding-left: 80px; } }

.catalog-list-item__image {
  position: absolute;
  left: 40px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0; }
  .catalog-list-item__image img {
    max-width: 319px;
    max-height: 384px;
    width: auto;
    height: auto; }
  @media (max-width: 1469px) {
    .catalog-list-item__image {
      left: 30px; }
      .catalog-list-item__image img {
        max-width: 220px;
        max-height: 280px; } }
  @media (max-width: 1214px) {
    .catalog-list-item__image {
      left: 20px; }
      .catalog-list-item__image img {
        max-width: 150px;
        max-height: 200px; } }
  @media (max-width: 540px) {
    .catalog-list-item__image {
      left: 0px;
      transform: translate(0);
      top: 0; }
      .catalog-list-item__image img {
        max-width: 60px;
        max-height: 80px; } }

.catalog-list-container {
  width: 100%; }
  .catalog-list-container .container {
    position: relative; }

.catalog-list-dots span {
  position: absolute;
  width: 313px;
  height: 313px;
  background: url("../img/blue_dots2.png") no-repeat 0 0;
  background-size: contain;
  z-index: 1; }
  .catalog-list-dots span:nth-child(1) {
    left: -418px;
    top: 85px; }
  .catalog-list-dots span:nth-child(2) {
    right: -298px;
    top: 390px; }
  .catalog-list-dots span:nth-child(3) {
    left: -223px;
    bottom: 185px; }

.catalog-item-popup {
  position: fixed;
  z-index: 1000;
  width: 50%;
  min-width: 960px;
  top: 0;
  right: 0;
  background: #fff;
  height: 100vh;
  padding-left: 170px;
  padding-top: 100px;
  padding-right: 60px;
  color: #3c3c3c;
  display: none; }
  .catalog-item-popup:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100px;
    left: 0;
    bottom: 0;
    background: linear-gradient(to top, white, rgba(255, 255, 255, 0));
    z-index: 1; }
  .catalog-item-popup img {
    margin-bottom: 20px; }
  .catalog-item-popup h3 {
    font-family: 'Echoes Sans', sans-serif;
    font-weight: 500;
    font-size: 63px;
    line-height: 0.873016;
    margin: 0;
    margin-bottom: 20px;
    font-variant: small-caps;
    letter-spacing: -0.005em;
    margin-bottom: 20px; }
  @media (max-width: 1469px) {
    .catalog-item-popup h3 {
      font-size: 59px; } }
  @media (max-width: 1214px) {
    .catalog-item-popup {
      padding-left: 100px;
      padding-top: 100px;
      padding-right: 50px; }
      .catalog-item-popup h3 {
        font-size: 50px; } }
  @media (max-width: 1006px) {
    .catalog-item-popup h3 {
      font-size: 40px; } }
  @media (max-width: 960px) {
    .catalog-item-popup {
      min-width: 0;
      width: 100%; } }
  @media (max-width: 754px) {
    .catalog-item-popup {
      padding-left: 30px;
      padding-top: 100px;
      padding-right: 10px;
      padding-bottom: 90px; }
      .catalog-item-popup h3 {
        font-size: 28px; } }

.catalog-item-popup__model {
  font-family: 'Echoes Sans', sans-serif;
  text-transform: uppercase;
  font-weight: 20px;
  font-weight: 300;
  margin-bottom: 30px; }

.catalog-item-popup__scroll {
  padding-right: 110px;
  position: relative;
  height: 100%;
  padding-bottom: 100px; }
  @media (max-width: 1214px) {
    .catalog-item-popup__scroll {
      padding-right: 50px; } }
  @media (max-width: 754px) {
    .catalog-item-popup__scroll {
      padding-right: 20px;
      height: calc(100% - 50px);
      padding-bottom: 0; } }

.catalog-item-popup__square span {
  width: 120px;
  height: 120px;
  position: absolute;
  background: #474747;
  z-index: 2; }
  .catalog-item-popup__square span:nth-child(1) {
    right: 0;
    top: 0; }
  .catalog-item-popup__square span:nth-child(2) {
    right: 0;
    bottom: 0; }
  .catalog-item-popup__square span:nth-child(3) {
    left: 0;
    bottom: 0; }

@media (max-width: 1214px) {
  .catalog-item-popup__square span {
    width: 80px;
    height: 80px; } }

.catalog-item-popup__close {
  position: absolute;
  width: 120px;
  height: 120px;
  left: 0;
  top: 0;
  cursor: pointer;
  background: #474747;
  border: 0;
  outline: none; }
  .catalog-item-popup__close:before {
    content: '';
    position: absolute;
    width: 30px;
    height: 30px;
    background: url("../img/close.png") no-repeat 0 0;
    background-size: contain;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }
  @media (max-width: 1214px) {
    .catalog-item-popup__close {
      width: 80px;
      height: 80px; }
      .catalog-item-popup__close:before {
        width: 20px;
        height: 20px; } }
